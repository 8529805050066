import React,{useState, useEffect} from 'react'
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {llamaApiCargaToken,  ErrorGeneral } from '../funciones/ClienteAxios';
import SelectList from './SelectList';
function SelectEdo({edo,onChangeSlct,tipo}) {
    const source = axios.CancelToken.source();
    const history = useHistory();
    const [listEdo,setListEdo] = useState([])
    const [espera,setEspera] = useState([])
    const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0;    
    const auxDispV =localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 
        ? localStorage.getItem('Dispvo'):'w8rf51v21dsd2cs'             
    useEffect(() => {
        const llamadaListEdo = () => {
            const data = qs.stringify({
                usuario: usuID,  idDispositivo: auxDispV,
            });
            const url = "lugar-estado";
    
            function respuesta(auxiliar) {
                if (auxiliar[0].Id != -1) {                
                    return setListEdo(auxiliar)
                }else{
                    setListEdo([])
                }
            }    
            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
        }
        llamadaListEdo()
        return ()=> {
            source.cancel();
        }  
    }, [])    

  return (
    listEdo.length > 0 ?                       
    <SelectList lista={listEdo} value={edo} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Estado"} 
        estilo={{minWidth:14+'rem'}} nameEtq={"edo"} tipo={tipo}
    />            
    :null   
  )
}

export default SelectEdo