import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Card,CardContent,Box, Typography} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'; 
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
const useStyles = makeStyles((theme) => ({
    card:{
      //height:70,
      width:32+'rem',
     // padding:'1.1 rem 0px 1.12rem',
        padding:0,
        margin:0,
      
    },       
}));


export default function DatosCard(){
    const classes = useStyles();
    const[listPart,setListPart]=React.useState([])
    const[espera,setEspera]=React.useState(false);
    let history = useHistory();
    useEffect(() => {
        async function llamadaListPart(){ 
            let id =localStorage.getItem('UsuId') ;         
            let token =localStorage.getItem('token20') ;
            setEspera(false)                            
            let auxU=process.env.REACT_APP_LINK +`partido-list`;            
            let dataInfo = qs.stringify({
                'usuario': id,                
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo
            };    
            let aux ={};
            await axios(config)
            .then(function (response) {       
                aux =  response.data;        
                 //console.log(aux);
                if (aux[0].PartId>=0) 
                {                         
                    setListPart(aux);                                  
                }
            })
            .catch(function (error) {
                console.log(error);
                if ( ( error && error.response  && error.response.data && error.response.data.error && error.response.data.error.name)
                        && ( error.response.data.error.name === "JsonWebTokenError" || error.response.data.error.name === "TokenExpiredError" )) 
                {   localStorage.clear();                    
                    history.push("/");                                          
                }
                if ( error && error.response  && error.response.data && error.response.data.mensaje 
                    && error.response.data.mensaje === "Datos Incorrectos") 
                {
                    console.log('sin datos' );                    
                }
            });
            setEspera(true)        
        }
        llamadaListPart()
        
        //console.log(valor);     
    }, [ ])
    
    function generarCards(nomPart,auxColor,partTotal){
        return(     
            <Box display={"flex"} mr={1}>                
                <FiberManualRecordIcon style={{color:auxColor,width:18+'px',paddingBottom:.3+'rem'}} />
                <Typography variant='body2'>{partTotal+" "+nomPart}</Typography>
            </Box>
      
        )
    }
    
    return (         
    <Box display="flex" flexDirection="row"   >               
    <Card className={classes.card}>
        <CardContent>
            {listPart.length>0 ?
            <Box display={"flex"} flexWrap="wrap" flexDirection="row" >
                {listPart.map((parti)=>{
                    return(                        
                        generarCards(parti.PartNom,parti.PartColor,parti.PartTotal)                        
                    )                    
                })      
                }
                </Box>                                  
            :null
            }
              </CardContent>
    </Card>                
    </Box>
    );
}
 