import React,{useState,useEffect} from 'react';
import {Box, LinearProgress,TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {authUser} from "../funciones/AuthUser";
//import {AuthContext} from '../context/AuthContext'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import PanelVoto from './PrepPanelVoto';
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
  const listaProceso=[
    //{ value:3, label:'2022'}, 
    { value:1, label:'2021'}, 
    //{ value:1, label:'2018'},
]

export default function Prep(){
  let history = useHistory();
  const source = axios.CancelToken.source();
  const usu = localStorage.getItem('UsuarioNom');            
  const nvl=  localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;        
  const auxId =localStorage.getItem('UsuId') ;    
  const {usuario,guardarUsuario}=React.useContext(AuthContext);
  const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
  const [listaRes,setListaRes] = useState([]);
  const [procesoSelec,setProcesoSelec] = useState([])
  const [espera,setEspera] = useState(false);
  const [actualizar,setActualizar] = useState(0); 
  const [listProcesos,setListProcesos] = useState([]); 
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
  useEffect(()=>{        
            
    const autenticado = authUser();
    if(!autenticado){
      localStorage.clear();
      history.push('/');
      guardarUsuario([]);  
    }
    else
    {
      if (usuario.length===0) 
      {
        guardarUsuario({Nombre:usu,nivel:nvl});                                         
      
      }  
      guardarGenerales({...generales, Titulo: 'Votación'})   
      getListProcesos();
    }
    
  },[])

  useEffect(()=>{
    if (procesoSelec!=="") {
      getLista(procesoSelec.Id)
    }
    
  },[actualizar,procesoSelec])

  const getListProcesos = () => {  
    const data = qs.stringify({
      Usuario:auxId,
      Estado:process.env.REACT_APP_EDO,
      idDispositivo: auxDispV,
    });
    const url = "votacion/proceso";
    function respuesta(aux) {
      if (aux) 
      { //  console.log(aux);
        setListProcesos(aux);    
      }else{
        setListProcesos([])
      }    
    }
    llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
  };

  const getLista = (procesoSelec) => {  
    const data = qs.stringify({
        periodo: procesoSelec,
        idDispositivo: auxDispV,
    });
    const url = "votacion-total";
    function respuesta(aux) {
      if (aux) 
      { //  console.log(aux);
        setListaRes(aux);    
      }else{
        setListaRes([])
      }    
    }
    llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
  };

  const handleChange =(e) =>{
    if (e!== null && e!== undefined) 
    {               
      setProcesoSelec( e )      
    }else
    {   setProcesoSelec("" )           
    } 
  }

  function generarSelect(){
    return(
    <Autocomplete
      id="proceso" size='small'
      onChange={(e,option)=>handleChange(option)}
      options={listProcesos}  value={procesoSelec} 
      getOptionLabel={(option) => option.Nom}
      style={{width: 13+'rem' }}
      noOptionsText={"Sin coincidencias"}
      renderInput={(params) => (
        <TextField {...params} 
        label="Proceso" variant="outlined" />
    )}/>
    )
  }
  const contenido = listaRes && procesoSelec.length !==0 ? 
    <PanelVoto datos={listaRes} setActualizar={setActualizar} actualizar={actualizar} procSel={procesoSelec?.Id}/> : null;

  const valCont = espera ? <Box sx={{width:'600px'}} style={{marginTop:'1em'}}><LinearProgress/></Box> : contenido;
  return( 
  <Box style={{marginLeft:'2em',marginTop:'1.5rem'}} display="flex" flexDirection="column">
    {generarSelect()}
    {valCont}
  </Box>)
  }