import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Card,CardContent,Box} from "@material-ui/core";
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme) => ({
    card:{
      height:3.5+'rem',
      width:130,     
      marginRight:'.5em',
    },
    titulo:{        
        textAlign:'center',
        marginTop:'-.4rem',
        fontSize:'1rem',
        fontWeight:600
    },
    tituloColor:{
        textAlign:'center',
        marginTop:'-.6rem',
        fontSize:'1rem',
        fontWeight:600
    },
    subtitulo:{        
        textAlign:'center',
        marginTop:'-1.4rem',
        fontSize:'.8rem',
    },
    subtituloCard:{       
        textAlign:'center',
        marginTop:'.28rem',
        fontSize:'.8rem',
    },
}));


export default function CardPerfil({valor}){
    const classes = useStyles();
    
    function generarCards(mensaje,datos,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datos} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }
  
    return (         
    <Box display="flex" flexDirection="row" mb={1}  >   
        <Box display="flex" flexDirection="row">                                    
            <Card className={classes.card}>
                {generarCards('Valido',valor.Valido,'#19A11E')}
            </Card>
            <Card className={classes.card}>
                {generarCards('Pendiente',valor.Pendiente ,'#FBBE06')}
            </Card>
            <Card className={classes.card}>
                {generarCards('Rechazado', valor.Rechazado,'#E91E63')}
            </Card>            
        </Box>   
    </Box>
    );
}
 