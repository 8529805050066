import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contenedorCard: {
    //paddingRight:'3rem'
    height: 4.6 + "rem",
  },
  card: {
    boxShadow:
      "0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)",
    //color:'#4a4a4a',
  },
  tituloGraf: {
    paddingTop: "0rem",
    textAlign: "center",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function GraficasSeccion({ valor,tipo }) {
  const classes = useStyles();  
  const[ArraySeccion,setArraySeccion]=useState({arrColor:[],arrTitulo:[],arrValor:[],arrHover:[]})
  const {arrColor,arrTitulo,arrValor,arrHover}=ArraySeccion
  useEffect(() => {
    if (valor.length!==0) {
      let auxVal=[]
      let auxCol=[]
      let auxNom=[]
      let auxBGCol=[]
      valor.forEach(element => {
        auxVal.push(element.avance)
        auxCol.push(element.color)
        auxNom.push(element.nombre)
        auxBGCol.push(genHoverColor(element.color))
      });      
      setArraySeccion({arrColor:auxCol,arrTitulo:auxNom,arrValor:auxVal ,arrHover:auxBGCol})
    }
  }, [valor]);

  function genHoverColor(aux){
    let respuesta="#d65657"
    let bandera=true
    if (bandera && aux==="#c21214") {
      respuesta="#d65657"
      bandera=false
    }
    if (bandera && aux==="#ea811d") {
      respuesta="#e0ac79"
      bandera=false
    }
    if (bandera && aux==="#ead71d") {
      respuesta="#e8de7d"
      bandera=false
    }
    if (bandera && aux==="#7bc212") {
      respuesta="#99cc4e"
      bandera=false
    }
    if (bandera && aux==="#127bc2") {
      respuesta="#33543c"       
    }
    return respuesta
  }

  const data = {
    labels: arrTitulo,
    datasets: [
      {
        data: arrValor,
        backgroundColor: arrColor,
        hoverBackgroundColor: arrHover,
      },
    ],
  };

  const options = {
    //responsive: true,
    maintainAspectRatio : false,
    plugins: {
      legend: {
        display: false,
      },
    },
    indexAxis: "y",
    tooltips: {
      enabled: true,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    title: {
      fontStyle: "bold",
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        //stacked: true,
        label: {
          fontSize: 6,
        },
      },
    },
  };

  function llenadoTabla2(datos, nombre) {
    return (      
      <Card
        className={classes.card}
        style={{
          paddingRight: 0.4 + "rem",
          paddingLeft: 0.4 + "rem",
          paddingBottom: 0.5 + "rem",                    
        }}
      >
        <Bar
          id="graficaSecc1" data={datos}
          //width={225} height={70}
          options={options}
        />
      </Card>      
    );
  }
  return (
    <Box
      display="flex" flexDirection="row"
      justifyContent={"center"} 
      //style={{ height: 10 + "rem" }}
    >
      {arrColor.length!==0 && arrTitulo.length!==0 && arrValor.length!==0 ? 
        ( tipo === 1 ?
            <Box display="flex" style={{height:34+'rem',
            width: 20 + "rem",}} >
              {llenadoTabla2(data, process.env.REACT_APP_Promov)}
            </Box>
          : <Box display="flex" style={{height:5+'rem',
           width: 18 + "rem",}} >
            {llenadoTabla2(data, process.env.REACT_APP_Promov)}
          </Box>
        ) 
        :  null
      }
    </Box>
  );
}
