import React,{useState,useEffect} from 'react';
import {Box, IconButton,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,
    Tooltip,Card,TablePagination,} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import moment from 'moment';
import PaginacionTab from '../layout/PaginacionTab'
import { useHistory } from "react-router-dom";
import axios from 'axios';

const useStyles = makeStyles({

    container: {
      minWidth: '800px',
      maxWidth:'70%',
      minHeight: 150,
     // backgroundColor:'white',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    buscador:{
        width:'30em',
        marginLeft:'.8em'
    },

    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:  process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

  const columnas=[
    {   id:8, titulo:"Fecha Nacimiento", alinea:'center' },
    { id:2, titulo:"Nombre",alinea:'center'},

];

export default function TablaCumple({lista, }){
    const alturaTabla = window.innerHeight<937 ? (lista.length>300 ? window.innerHeight-410 : window.innerHeight-270) 
                                               : (lista.length>300 ? window.innerHeight-450 : window.innerHeight-370)
    const classes = useStyles();
    const history = useHistory();
    let auxArreglo=[]
    const fondo=process.env.REACT_APP_Fondo_Color
    const source = axios.CancelToken.source();
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
    const [listaInciden,setListaInciden] = useState(lista)
    const [seleccion,setSeleccion]=  useState(null);      
    const [incidencia,guardarIncidencia] = useState({ nombre:''});        
    const [totalPaginas,setTotalPaginas]=useState(1);
    const [numPagina,setNumPagina]=useState(0);
    const [seleccionado,setSeleccionado]=useState(null);
    const {nombre} = incidencia;
    useEffect(()=>{
        console.log(lista);
        if(lista.length>300)
        {   let auxTam = lista.length;
            if(auxTam%300===0)
            {
                let pagina=Math.trunc(auxTam/300)
                setTotalPaginas(pagina)   
            }
            else
            {   let pagina=Math.trunc(auxTam/300)
                pagina=pagina+1;
                setTotalPaginas(pagina)
            }
            setNumPagina(0) 
            setListaInciden(lista.slice(0,300))
        }
        else
        {   setTotalPaginas(1)
            setNumPagina(0)
            setListaInciden(lista)
        }
    },[lista])
    
    const exportarArch = () => {
        let today = new Date();                    
        let fec = moment(today);
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        const auxTipo=""
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Incidencias de tipo "+auxTipo+".xlsx")
    };

    function selecFila(reg){                
        setSeleccion(reg);
    }
    
    function filtro(){        
        let auxCadena=nombre.trim();                 
        auxArreglo=[]               
        let info=listaInciden.filter((dat)=>dat.Descr.search(auxCadena) !== -1)    
        return generarTabla(info);
    }

    function onChangePagina(event, newPage){
        event.preventDefault();
        if (newPage ===0) 
        {   setListaInciden(lista.slice(0,300))   
        }
        else
        {   setListaInciden(lista.slice(newPage * 300, newPage * 300 + 300))   
        }
        setNumPagina(newPage)
    }

    function sinDatos(){
        return(
        <TableContainer className={classes.container} style={{height: listaInciden.length>0 ? alturaTabla : 150}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow >                                        
                    <TableCell padding="none" align="center" style={{backgroundColor:fondo,color:'white'}}>
                    {listaInciden.length}    
                    </TableCell>                                  
                    {columnas.map(row=>{
                        return <TableCell padding='none' align={row.alinea} key={row.id} 
                            style={{backgroundColor:fondo,color:'white'}}>{row.titulo}</TableCell>
                    })}                                    
                </TableRow>                        
            </TableHead>  
            <TableBody> 
                <TableRow>
                    <TableCell padding='none' align="center" style={{width:2,paddingLeft:'.2rem'}}></TableCell>
                    <TableCell padding='none' align="center" style={{width:25,paddingLeft:'.2rem'}}></TableCell>
                    <TableCell padding='none' align="center" style={{width:25,paddingLeft:'.2rem'}}></TableCell>
                    <TableCell padding='none' align="center">Sin</TableCell>               
                    <TableCell padding='none' align="center">Resultados</TableCell>
                    <TableCell padding='none' align="center"></TableCell>
                    <TableCell padding='none' align="center">Disponibles</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const celda = (alinear, estilo, contenido) => {
      return (<TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo}>
          {contenido}
      </TableCell>)
    }
    
    function generarTabla(lista) {        
      let numRenglon=0;
      const tot= Object.keys(lista).length;
      let auxi2=["#","Horario","Sección","Casilla","Descripción"];
      auxArreglo.push(auxi2);
      if(tot===0)
      {   const auxi3=["","Sin","Incidencias" ,"de este ","tipo"]
          auxArreglo.push(auxi3);            
      }             
      return(
      <TableContainer className={classes.container} style={{height: listaInciden.length>0 ? alturaTabla : 150}}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow >                                        
              <TableCell padding="none" align="center" style={{backgroundColor:fondo,color:'white'}}>
                {`${listaInciden.length} Sección`}    
              </TableCell>                                  
              {columnas.map(row=>{
                return <TableCell padding='none' align={row.alinea} key={row.id} style={{backgroundColor:fondo,color:'white'}}>{row.titulo}</TableCell>
              })}                                    
            </TableRow>                        
        </TableHead>  
        <TableBody> 
        {  lista.map((inciden,index) => {
            const auxi=[ index+1 , inciden.Horario , inciden.SecNom,  inciden.Descr ]
            auxArreglo.push(auxi);
            numRenglon++;     
            const bandera=seleccion?.fecnac === inciden?.fecnac ? true :false
            const fchCump=moment(inciden.fecnac).format("DD/MM/YYYY")
            return(                                                                                                                  
            <TableRow className={classes.tableRow} selected={bandera}  key={numRenglon}
              classes={{ hover: classes.hover, selected: classes.selected }} onClick={ () => selecFila(inciden) }
            >                                                                                     
              {celda("center",{width:2+'rem'}, <b>{inciden.SecNum}</b>)}
              {celda("center",{width:2+'rem'}, <b>{fchCump}</b>)}      
              {celda("left",{fontSize:'14px',width:16+'rem',paddingLeft:'.4em',fontWeight:500}, 
                `${inciden.nom} ${inciden.pat} ${inciden.mat}` )}                                                                                                                                                                  
            </TableRow>              
            );                        
        })}
        </TableBody>
      </Table>
      </TableContainer>
      )                
    }           

    function generarFooter(){
      return(
      <TablePagination  style={{padding:0}}
        rowsPerPageOptions={false}
        colSpan={3} rowsPerPage={300}
        count={lista.length} page={numPagina}
        lang='es'  onPageChange={onChangePagina}
        ActionsComponent={PaginacionTab}
        labelDisplayedRows={
          ({ from, to, count }) => {
          return '' + from + '-' + to + ' de ' + count
          }
        }
      />)
    }

 
 
    //const contenido = incidencia? (filtro()):(generarTabla(listaInciden));
    const validaCont = listaInciden.length!==0 ? generarTabla(listaInciden) : sinDatos();
    const paginacion = totalPaginas>1 ? generarFooter() : null;

    return (
    <div style={{marginTop:'.5em'}}>
     {/*  <Card style={{height:'3.5rem',marginBottom:'.5rem',width:'510px'}}>
        <Box display="flex" flexDirection="row" alignContent={"center"} style={{marginTop:".65rem",marginLeft:".5rem"}}>
        
          <IconButton  onClick={exportarArch} style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}}>
            <Tooltip title="Exportar Incidencias">
              <SaveIcon color="primary"/>
            </Tooltip>
          </IconButton>
        </Box>
      </Card>    */}     
      {paginacion}
      {validaCont}
      
    </div>
    );
}
 