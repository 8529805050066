import React,{useEffect,useState} from 'react';
import {HorizontalBar,Bar} from 'react-chartjs-2';
import {Card,CardContent,Box, Typography} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    //paddingRight:'3rem'
    height:10+'rem',marginRight:".3rem"
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    //color:'#4a4a4a',
  },
  tituloGraf:{
    paddingTop:'0rem',
    textAlign:'center'
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function Graficas({valor, listAvMun}){
  const classes = useStyles();
  const color1= '#19A11E';  //ff1744  
  const color2= '#FBBE06';
  const auxColor=process.env.REACT_APP_Fondo_Color
  const [data,setData]= useState([]);
  const [datEdad,setDatEdad]= useState([]);
  const [datRef,setDatRef]= useState([]);
  const [bandera,setBandera]= useState(false);
  useEffect(()=>{    
    const{  
      HTot, MTot, XTot,
      Tot25, Tot35, Tot45,
      Tot55, Tot65, Tot70,

      }=valor[0]            
    /* if (listAvMun.referente)
    { 

      setDatRef({rv:listAvMun.referente[0].Total, pan:listAvMun.referente[1].Total, 
        pri:listAvMun.referente[2].Total, prd:listAvMun.referente[3].Total,
        nva:listAvMun.referente[4].Total})
    } */
    setData({Hombre:HTot,Mujer:MTot,Binario:XTot})
    setDatEdad({Tot25:Tot25, Tot35:Tot35, Tot45:Tot45,
      Tot55:Tot55, Tot65:Tot65, Tot70:Tot70,})
 
    setBandera(true)
  },[valor])

  const dataGenero = {
    labels: ['Género'],
    datasets: [
    { type: 'bar', label:[ 'No Binario' ],
        data:[data.Binario],//[10, 20, 30, 40],
        backgroundColor:  [ "#4BAC7D50"]//'rgba(255, 99, 132, 0.2)',         
      },              
      { label:'Hombre Total',
        type: 'bar',
        data: [data.Hombre], //[50, 40, 30, 50],
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',
        //fill: false,
        tension: 0.4           
      },
      { label:'Mujer Total',
        type: 'bar',
        data: [data.Mujer], //[50, 40, 30, 50],
        backgroundColor:'#C52363',
        borderColor: '#C52363',
        //fill: false,
        tension: 0.4   
        
      },   
    ],
  }; 

  const dataEdad = {
    labels: ['Edad'],
    datasets: [
    { type: 'bar', label:[ '18 a 25' ],
        data:[datEdad.Tot25],//[10, 20, 30, 40],
        backgroundColor:  [ "#4BAC7D50"]//'rgba(255, 99, 132, 0.2)',         
      },              
      { label:'26 a 35',
        type: 'bar',
        data: [datEdad.Tot35], //[50, 40, 30, 50],
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',
        //fill: false,
        tension: 0.4           
      },
      { label:'36 a 45',
        type: 'bar',
        data: [datEdad.Tot45], //[50, 40, 30, 50],
        backgroundColor:'#C52363',
        borderColor: '#C52363',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'46 a 55',
        type: 'bar',
        data: [datEdad.Tot55], //[50, 40, 30, 50],
        backgroundColor:'#B406D8',
        borderColor: '#B406D8',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'56 a 65',
        type: 'bar',
        data: [datEdad.Tot65], //[50, 40, 30, 50],
        backgroundColor:'#FFA318',
        borderColor: '#FFA318',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'Mayor de 65',
        type: 'bar',
        data: [datEdad.Tot70], //[50, 40, 30, 50],
        backgroundColor:'#7C594C',
        borderColor: '#7C594C',
        //fill: false,
        tension: 0.4   
        
      },   
    ],
  }; 

  const dataReferente = {
    labels: ['Referente'],
    datasets: [
    { type: 'bar', label:[ 'AbigailVa' ],
        data:[datRef.rv],//[10, 20, 30, 40],
        backgroundColor:  [ "#347AF4"]//'rgba(255, 99, 132, 0.2)',         
      },              
      { label:'PAN',
        type: 'bar',
        data: [datRef.pan], //[50, 40, 30, 50],
        backgroundColor:'#347AF4',
        borderColor: '#347AF4',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'PRI',
        type: 'bar',
        data: [datRef.pri], //[50, 40, 30, 50],
        backgroundColor:'#ED0808',
        borderColor: '#ED0808',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'PRD',
        type: 'bar',
        data: [datRef.prd], //[50, 40, 30, 50],
        backgroundColor:'#FFA318',
        borderColor: '#FFA318',
        //fill: false,
        tension: 0.4   
        
      },   
      { label:'NVA',
        type: 'bar',
        data: [datRef.nva], //[50, 40, 30, 50],
        backgroundColor:'#0097A7',
        borderColor: '#0097A7',
        //fill: false,
        tension: 0.4   
        
      },   
    ],
  }; 

  const options = {
  
  //  indexAxis:'y',
    tooltips: {
      enabled: true,
    },
   /*  layout: {
      padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
      }
    }, */
    title:{
      fontStyle: 'bold',
    },
    scales: {
      x: {
          display: true,
          title: {
          display: true
          }
      },
      y: {
          display: true,
          title: {
          display: false,
       
          },
       
      },
      }
   /*  scales: {
      x: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
        labe :{
          fontSize:6,
        },
      }
    
    }, */
  } 

  function  llenadoTabla2(datos,)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
      ,paddingBottom:.5+'rem',height:10+'rem',width:25+'rem'}} >              
      <Bar
        id="graficaMuni"
        data={datos}
        width={400} height={200} 
        options={options}
      />   
    </Card>
    );
  }

  return (
  <Box display="flex" flexDirection="row" justifyContent={'center'} mb={1} style={{marginTop:1+'rem'}} >           
    <Box display="flex"className={classes.contenedorCard}  >
       {/* bandera ?llenadoTabla2(dataReferente):null */}
      </Box>  
     <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataGenero):null}
      </Box>      
      <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataEdad):null}
      </Box>     
     {/*  <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(Grafica3,process.env.REACT_APP_Promov):null}
      </Box>    */}  
  </Box>
  );
}