import {useEffect, useState,useContext} from "react";
import {useHistory} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import {GeneralesContext} from '../context/GeneralesContext'
import { Box, Button, Card, CardContent, Grid, IconButton, 
    LinearProgress, Paper, Tooltip, Typography,TextField,
} from "@material-ui/core";
import TablaOrganizacion from "./TablaOrganizacion";
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from "../funciones/ClienteAxios";
import Galeria from "./Galeria";
import {AddCircleOutlined} from "@material-ui/icons";
import ModalNvoEdit from "./ModalNvoEdit";
import ModalAgregarEvidencia from "./ModalAgregarEvidencia";

const useStyles = makeStyles((theme) => ({}));

const Organizacion = () => {
    const classes = useStyles();
    const idusu = localStorage.getItem("UsuId")?parseInt(localStorage.getItem("UsuId")):0;
    const usuTipo = localStorage.getItem('Tipo');
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'  
    const history = useHistory();
    const source = axios.CancelToken.source();
    const {generales, guardarGenerales} = useContext(GeneralesContext);
    const [selectedID, setSelectedID] = useState(null);
    const [recorrido, setRecorrido] = useState([]);
    const [galeria, setGaleria] = useState([]);
    const [cargando, setCargando] = useState(false);    
    const [abrirModalAgregar, setAbrirModalAgregar] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [buscar, setBuscar] = useState(true);
    const [checked, setChecked] = useState(false);
    const [abrirModal, setAbrirModal] = useState(false);
    const [update, setUpdate] = useState(false);
    const [cadena, setCadena] = useState("")
    const [listMun, setListMun] = useState([])
    const [listDistrito, setListDistrito] = useState([])  
    const [listTipo, setListTipo] = useState([])  
    useEffect(() => {
        if (listMun.length === 0) {
            recuperarMunicipios()
        }
        if (listDistrito.length===0) 
        {   llenaListDistr()  
        }
        llenaListTipo()
        guardarGenerales({...generales, Titulo: 'Organizaciones'})
        return ()=> {
            source.cancel();
        }  
    }, []);

    useEffect(() => {
        setGaleria([]);       
        if (selectedID !== null) {
            consultarImagenes();
        }
    }, [selectedID,update]);
    
    function llenaListTipo()  {            
        const auxU= `organizacion-tipo-usuario`;                            
        const dataInfo = qs.stringify({     
            Usuario:idusu,                                        
            idDispositivo:auxDispV,
        });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListTipo(aux)
            }                                                            
        }
        llamadaApiToken(dataInfo, auxU, respuesta, ErrorGeneral, history, source);
    };
    
    function llenaListDistr()  {            
        const auxU= `lugar-distrito-tipo`;                            
        const dataInfo = qs.stringify({     
            usuario:idusu,
            tipo:"F",                                       
            idDispositivo:auxDispV,
        });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListDistrito(aux)
            }                                                            
        }
        llamadaApiToken(dataInfo, auxU, respuesta, ErrorGeneral, history, source);
    };

    const recuperarMunicipios = () => {
        const data = qs.stringify({
            usuario: idusu,
            estado: process.env.REACT_APP_EDO,
            tipo: usuTipo,
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar-municipio-tipo";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListMun(response)
            }
        }
    
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    const consultarImagenes = () => {
        const data = qs.stringify({
            Llave: selectedID.Id,
            Usuario: idusu,
            idDispositivo: "987gyubjhkkjh",
        });
        const url = "organizacion-file-list";

        function respuesta(response) {
            if (response[0].Id === -1) {
                setGaleria([]);
                setMensaje("Sin fotografías.");
            } else {
                setGaleria(response);
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const buscarDatos = () => {
        setBuscar(!buscar)
    }

    const recargar = () => {
        setUpdate(!update)
    }

    const modalAgregarEvidencia = abrirModal ? <ModalAgregarEvidencia abrirModal={abrirModal}
                                                setAbrirModal={setAbrirModal} recorrido={recorrido}
                                                selectedID={selectedID} idusu={idusu}  setUpdate={recargar}
                                            /> : null

    const modalAgregarRecorrido = abrirModalAgregar ? <ModalNvoEdit abrirModal={abrirModalAgregar}
                                                        setAbrirModal={setAbrirModalAgregar}
                                                        idusu={idusu} history={history}
                                                        listMun={listMun} actualiza={buscarDatos}
                                                        titulo="Nueva Organización"
                                                        listDistrito={listDistrito} listTipo={listTipo}
                                                    /> : null

    const agregarDatos = () => {
        setAbrirModalAgregar(true)
    }

    const listarGaleria =
        galeria.length > 0 ? (
            <Galeria
                galeria={galeria}
            />
        ) : (
            <Paper className={classes.root}>
                <Typography variant={"h5"}>{mensaje}</Typography>
            </Paper>
        );


    const handleAbrir = () => {
        setAbrirModal(true)
    }

    const onChange = e => {
        setCadena(  e.target.value )
    }

    const principal = () => {
        return (
        <Grid container spacing={1}>
            {modalAgregarRecorrido}
            <Grid item xs={12}>
                <Card>
                <CardContent style={{padding:0.4+'rem'}} >
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item>
                            <Tooltip title={'Buscar'}>
                                <IconButton
                                    color="primary" aria-label="Buscar"
                                    onClick={buscarDatos}
                                >
                                    <SearchIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>       
                        <Grid item>
                            <TextField id="cadena-Txt" name="cadena" label="Filtro" fullWidth size="small"
                                value={cadena} onChange={onChange} variant="outlined"/>                                                        
                        </Grid>
                        <Grid item>
                            <Tooltip title={'Agregar Organización'}>
                                <IconButton
                                    color="primary" onClick={agregarDatos}
                                    aria-label="Agregar Organización"                                    
                                >
                                    <AddCircleOutlined/>
                                </IconButton>
                            </Tooltip>
                        </Grid>                        
                    </Grid>
                </CardContent>
                </Card>
            </Grid>
            <Grid item xs={9}>
                <TablaOrganizacion
                    history={history} setRecorrido={setRecorrido}                    
                    selectedID={selectedID} setSelectedID={setSelectedID}
                    source={source} idusu={idusu} buscar={buscar}
                    checked={checked} listMun={listMun} actualiza={buscarDatos}
                    cadena={cadena} listDistrito={listDistrito}
                    listTipo={listTipo}
                />
            </Grid>
            <Grid item xs={3}>
                {modalAgregarEvidencia}
                {recorrido.Obsrv ? 
                    cargando ? (
                        <Box mt={2} mb={2} width="80%">
                            <LinearProgress/>
                        </Box>
                    ) 
                    : (
                        <Grid container>
                        <Grid item xs={12} style={{paddingBottom: 10}}>
                            <Typography color="primary">
                                Observación
                            </Typography>
                            <Typography>
                                {recorrido?.Obsrv}
                            </Typography>
                        </Grid>
                        </Grid>
                    ) 
                :null}
                {selectedID !== null ? 
                <Grid container>
                    <Grid item xs={12} style={{paddingBottom: 10}}>
                        <Button
                            variant="contained" color="primary"
                            startIcon={<AddCircleOutlined/>}
                            onClick={handleAbrir}
                        >
                            Subir Fotos
                        </Button>
                    </Grid>
                </Grid> 
                : null}
                {cargando ? (
                    <Box pl={1} pt={5}>
                        <LinearProgress/>
                    </Box>
                ) : (
                    listarGaleria
                )}
            </Grid>
        </Grid>
        );
    };

    return <div>{principal()}</div>;
};

export default Organizacion;
