import React,{useState,useEffect,useContext} from 'react';
import {Box,Button,LinearProgress,} from "@material-ui/core";
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import InciPanel from './InciPanel';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import {llamaApiCargaToken,ErrorGeneral,llamadaApiToken } from '../funciones/ClienteAxios';
import axios from 'axios';
import qs from 'qs';
import NuevaIncidencia from './NuevaIncidencia'; 

export default function Incidencia(){
    const history = useHistory();
    const source = axios.CancelToken.source();
    const usu= localStorage.getItem('UsuarioNom');    
    const auxId =localStorage.getItem('UsuId') ;            
    const Dispvo =localStorage.getItem('Dispvo') ;   
    const nvl= localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;       
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
    const [listInci,setListInci] = useState([])
    const [actualizar,setActualizar] = useState(0);
    const [espera,setEspera]=useState(false);
    const [listMuni,setListMuni] = useState([]);          
    const [listTipo,setListTipo] = useState([]);          
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);     
    const [modalNvo, setModalNvo] = useState(false);

    useEffect(()=>{
        
    const autenticado = authUser();
        
        if(!autenticado){
            localStorage.clear();
            history.push("/");
            //guardarUsuario([])
        }else{
            getIncidenciaList()    
            //incidenciaRedisClear()        
            llamadaListMuni() 
            llamadaListTipo()
            if (usuario.length===0) 
            {
                guardarUsuario({Nombre:usu,nivel:nvl}); 
                 guardarGenerales({...generales,Titulo:'Incidencias'})                                         
            }
                 
        }
        return ()=> {
            source.cancel();
        }  
    },[actualizar])

    const getIncidenciaList = () => {            
        const data = qs.stringify({
            usuario: auxId,                
            idDispositivo: auxDispV,
        });
        const url = "incidencia-total-xtipo"//"incidencia-web-list-gral";
        function respuesta(aux) {
            if (aux[0].Id!== -1)
            {   setListInci(aux)
            }
            else
            {   setListInci([])
            }    
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };

    function llamadaListMuni()  {            
        const auxU= `lugar-municipio`;                               
        const dataInfo =  
            qs.stringify({                
                usuario:auxId,              
                estado:process.env.REACT_APP_EDO,                
                idDispositivo:auxDispV,
            })        
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListMuni(aux)
            }                                                            
        }
        llamaApiCargaToken(dataInfo, auxU, respuesta, ErrorGeneral, setEspera, history, source);
    };
    
    function llamadaListTipo()  {            
        const auxU= `incidencia-tipo`;                               
        const dataInfo =  
            qs.stringify({                                          
                idDispositivo:auxDispV,
            })        
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
              
                const auxArreTipos=aux.filter((tipo)=>tipo.AreaId===2||tipo.AreaId===1);                        

                                                        
              
                setListTipo(auxArreTipos);  
            }                                                            
        }
        llamadaApiToken(dataInfo, auxU, respuesta, ErrorGeneral, history, source);
    };

   /*  const incidenciaRedisClear = () => {            
        const data = qs.stringify({                         
            idDispositivo: auxDispV,
        });
        const url = "incidencia-redis-clear";
        function respuesta(aux) {
            console.log("se limpiio");
        }
        llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source);
    }; */
    const recargar=()=>{
        setActualizar(!actualizar)
    }

    const modNvo = modalNvo ? <NuevaIncidencia titulo={"Nueva Incidencia"} modalAbierto={modalNvo} 
                                setModalAbierto={setModalNvo} listMuni={listMuni} recargar={recargar}/> : null;
    const contenido = listInci.length!==0 ? <InciPanel listaTp={listInci} actualizar={actualizar} setActualizar={setActualizar}
                                                listMuni={listMuni}  recargar={recargar}  listTipo={listTipo}/> 
                                            : <Button style={{marginTop:"1rem"}} variant='outlined' onClick={()=>setModalNvo(true)}>Agregar una Incidencia</Button>
    const validaCarga = espera ? (<Box style={{marginTop:'2rem',marginLeft:'1em',width:'600px'}} sx={{width:'100%'}}>
                                <LinearProgress/> </Box>) : contenido;
    return(
    <Box style={{width:'97%',marginLeft:'.5rem'}}>
        {validaCarga}
        {modNvo}
    </Box>)
}