import React,{useState, useEffect, useContext} from 'react';
import TablaGeneral from './TablaGeneral'
import {useHistory} from "react-router-dom";
import {Box,IconButton,Tooltip, Breadcrumbs,Typography,
  Card,TextField, LinearProgress} from "@material-ui/core";
import IntervaloSelect from './IntervaloTabla';
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CardPerfil  from './CardPerfil';
//import ContenedorMapa from './ContenedorMapa';
import { authUser } from '../funciones/AuthUser'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import MapDelegacion from '../MapaEdo';
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import SelectEdo from '../generales/SelectEdo';
import { obtenDispo } from '../funciones/IdDispositivo';
import {SelectList} from '../generales/Inputs';
import MapaGPS from '../MapGeneral';
import moment from 'moment';
const arrBase={
  "Id": 0, "MunId": 0, "MunNom": "TODOS",
  "MunNum": 0, "UsrId": 0, "UsrNom": "",
  "UsrTel": "0","UsrUsr":""
}
const PublicoInicio = () => {
  let history = useHistory();
  const usuID = localStorage.getItem('UsuId');
  const usu = localStorage.getItem('UsuarioNom');            
  const nvl=  localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;        
  const idusu = localStorage.getItem("UsuId") ? parseInt(localStorage.getItem("UsuId")):0;
  const source = axios.CancelToken.source();
  const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
  const auxDispV =obtenDispo()
  //const[totalPerfil,setTotalPerfil]= useState({Valido:0,Pendiente:0,Rechazado:0});
  const[totalPerfil,setTotalPerfil]= useState([]);
  const[totFiltro,setTotFiltro]= useState([]);
  const[actualizar,setActualizar]=useState(true);
  const [arregloColor,setArregloColor] = useState([])  
  const [infoNvlCoord,setInfoNvlCoord] = useState([])   
  const [listMun,setListMun]=useState([])
  const [munSvgSelect,setMunSvgSelect]=useState(0)
  const [listEncargado,setListEncargado]=useState([])
  const [cargaList,setCargaList]=useState([])
  const [encargado,setEncargado]=useState([])
  const[nombre, setNombre]=useState("")
  const[filtro,setFiltro]=useState({edo:"",TipoUsu:"",muni:"", geoEdo:"", geoMun:"", geoSecc:""})
  //const [listGeoJson,setListGeoJson]=useState([]);
  const {usuario,guardarUsuario}= useContext(AuthContext);
  const {generales,guardarGenerales}= useContext(GeneralesContext); 
  const [listGeoEdo,setListGeoEdo]=useState([]);
  const [listGeoMun,setListGeoMun]=useState([]);
  const [listGeoSecc,setListGeoSecc]=useState([]);
  const [listGpsSecc,setListGpsSecc]=useState([]);
  const [mapaGeOJson,setMapGeoJson]=useState("");
  const{edo,TipoUsu,muni, geoEdo, geoMun, geoSecc}=filtro
 
  useEffect(() => {    

    veriSesion()
    return () => {
      source.cancel();
    }
  }, [])

  useEffect(() => {    
    if(geoSecc!==""){
      getlistGPSSecc(geoMun,geoSecc)
    }    
    
  }, [geoSecc])
  
  useEffect(() => {    
    if(geoMun!==""){
      getlistGPSSecc(geoMun,0)
    }    
    
  }, [geoMun])

  const veriSesion =  () => {            
    let auxValidacion=authUser()
    if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
        ||(usuID===undefined||usuID===''||usuID===null) )
    {   guardarUsuario([])				
      guardarGenerales([])    
      localStorage.clear();            	             
      history.push("/")	                	
    }
    else
    { if (usuario.length==0) 
      {   guardarUsuario({Nombre:usu,nivel:nvl});                                                         
      }                                              
      guardarGenerales({...generales,Titulo:'Promovidos Publico'})                                                       
    }   
  } 

  const getlistGPSSecc = (geoMun,geoSecc) => {
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");   
    const data = qs.stringify({                
        usuario:idusu,
        status:0,
        estado:geoEdo,
        municipio:geoMun,
        seccion:geoSecc,
        otro:"",
        cadena:"",
        fini:`${fInicio}T00:00:00`,
        ffin:`${fFinal}T23:59:59`,
        idDispositivo: auxDispV,
    });
    const url = "perfil/publico/gps/muni/secc";

    function respuesta(aux) {
      if (aux[0].Id!==-1 ) 
      {        
        setListGpsSecc(aux)
      } 
      else 
      {  
        setListGpsSecc([])
      }
    }

    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargaList, history, source);
  };

  const generarLista = e =>{   
    e.preventDefault()    
    setTotalPerfil([])
    setArregloColor([])
    setActualizar(!actualizar)    
    setMapGeoJson("")
    setListGeoMun([])      
    setListGeoSecc([])      
    setFiltro({...filtro,geoEdo:'',
      geoMun:"", geoSecc:'',})
    setListGpsSecc([])
    setTotFiltro([])
  }

  const filtroSlct=(e)=>{
    let arr=[], filtrado=[]
    switch (e.target.name) {                
      case 'geoEdo':
        filtrado=listGeoEdo.filter((estado)=>estado.Id === e.target.value)        
        arr={...filtro,geoEdo:e.target.value,
          geoMun:"", geoSecc:'',}        
        //console.log(filtrado[0].municipio);
        setMapGeoJson(filtrado[0].Geo??"")
        setListGeoMun(filtrado[0].municipio ?? [])      
        setListGeoSecc([])    
        setListGpsSecc([]) 
        setTotFiltro({Valido:filtrado[0].Vali, Pendiente:filtrado[0].Pend, Rechazado:filtrado[0].Rech})       
        break
      case 'geoMun':
        filtrado=listGeoMun.filter((municipio)=>municipio.Id === e.target.value)        
        arr={...filtro,geoMun:e.target.value, geoSecc:''}
        setListGeoSecc(filtrado[0].seccion ?? [])    
        setListGpsSecc([])
        setTotFiltro({Valido:filtrado[0].Vali, Pendiente:filtrado[0].Pend, Rechazado:filtrado[0].Rech})       
        break
      case 'geoSecc':
        filtrado=listGeoSecc.filter((seccion)=>seccion.Id === e.target.value)        
        arr= {...filtro,[e.target.name]:e.target.value}
        setTotFiltro({Valido:filtrado[0].Vali, Pendiente:filtrado[0].Pend, Rechazado:filtrado[0].Rech})       
        break
      default:
        arr= {...filtro,[e.target.name]:e.target.value}
        break;
    }
    //setMensaje('');                
    //setAviso(false);   
    setFiltro(arr)
  }

  const onChange = e => {
    setNombre( e.target.value)
  }

  const onChangeSlct=(e)=>{    
    if (e!== null && e!== undefined) 
    {            
      filtroSlct(e)
    }
    else
    {   setFiltro({...filtro,[e.target.name]:""})   
   
    }        
  }

  const slctGeoEdo =                        
  <SelectList lista={listGeoEdo} value={geoEdo} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Estado"} nameEtq={"geoEdo"}
      estilo={{marginLeft:'1rem',width:'13.5rem'}} 
  />   

  const slctGeoMun =                        
  <SelectList lista={listGeoMun} value={geoMun} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Municipio"} nameEtq={"geoMun"}
      estilo={{marginLeft:'1rem',width:'14.5rem'}} 
  />  

  const slctGeoSecc =                        
  <SelectList lista={listGeoSecc} value={geoSecc} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Sección"} nameEtq={"geoSecc"}
      estilo={{marginLeft:'1rem',width:'14.5rem'}} 
  />  

  //const cardMuni= listMun.length!==0 && munSvgSelect!== 0 ? generaCard(munSvgSelect):null
  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={generarLista}>
            <Tooltip title="Buscar">
            <SearchIcon/>
            </Tooltip>
        </IconButton>                                                                                                                              
      </Box>    
      {idusu === 1 ?
          <Box pl={1} pt={1}>
            <TextField id="nombre-txt" name="nombre" label="Nombre" fullWidth size="small"
              value={nombre} onChange={onChange} variant="outlined"/>  
          </Box>
        :null }
      <Box display={"flex"}>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
              
        <Box ml={1} pb={1} >
          <SelectEdo edo={edo} onChangeSlct={onChangeSlct} />
        </Box>         
      </Box>
    </Box>   
    {/* infoNvlCoord.length > 0 ? nvlInfo(infoNvlCoord[0]) :null */}
    <Box>
      {totalPerfil.length !== 0 ? 
        <CardPerfil valor={totalPerfil} />
      :null}
    </Box>
    <Box display={"flex"} row  width={"100%"} >
      <Box width={"71%"} style={{minWidth:63+'rem'}} >
      <TablaGeneral fSelect={fSelect} actualizar={actualizar} setActualizar={setActualizar}
        setTotalPerfil={setTotalPerfil} setArregloColor={setArregloColor}
        setInfoNvlCoord={setInfoNvlCoord } setListMun={setListMun} listMun={listMun} 
        nombre={nombre} edo={edo} setListGeoEdo={setListGeoEdo}
      />
      </Box>
      {arregloColor.length !== 0 ?
      <Box display={"flex"} flexDirection="column"  style={{minWidth:20+'rem'}} >
        {/* <span  >
          {cardMuni}
          <ContenedorMapa coloreados={arregloColor} setMunSvgSelect={setMunSvgSelect}/>
        </span> */}
        <Box display={"flex"}>
          {listGeoEdo.length!==0? slctGeoEdo:null}
          { listGeoMun.length!==0? slctGeoMun:null }            
        </Box>
        
        <Box mt={1} mb={1}>
        { listGeoSecc.length!==0? slctGeoSecc:null }
        </Box>
        {totFiltro.length !== 0 ? 
          <Box ml={2}>
            <CardPerfil valor={totFiltro} />
          </Box>          
        :null}
        {mapaGeOJson!=="" && listGpsSecc.length===0?<span style={{marginLeft:1+'rem'}} >
          <MapDelegacion arregloColor={arregloColor} mapaGeOJson={mapaGeOJson}/> 
        </span>:null}
        {listGpsSecc.length!==0 ? 
          <MapaGPS muniGps={listGpsSecc} zoom={13}
            tipo={1} coordPerfiles={[]} auxMapVar={[]} />
        :null}
      </Box>
      :null
      }
    </Box>
  </div>
  );
};

export default PublicoInicio;