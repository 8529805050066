import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card,Box, Typography, TablePagination, IconButton, Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PaginacionTab from '../layout/PaginacionTab'
import SaveIcon from '@material-ui/icons/Save';
const useStyles = makeStyles({
    estiloCard:{
      width: '26rem',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      // color:'#4a4a4a',
      display:'block',
      padding:'.5rem',
      maxHeight:'100%',
      minHeight: 280,
      marginRight:'.5rem'
    },
    tablaContainer: {
      width: '25.5rem',
      maxHeight: '31.5rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    totalEstilo:{
      fontSize:'1.25rem',
      fontWeight:'bold'
    },
    tituloEstilo:{
      paddingBottom:'.25rem',
      fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  }); 

export default function TablaAgrupamiento({listAv,}){    
  const classes = useStyles();      
  const [datos,setDatos] = useState([]);     
  const [registro, setRegistro] = useState([]);     
  const [totalGen,setTotalGen] = useState([]);    
  const [totalPaginas,setTotalPaginas]=useState(1);
  const [numPagina,setNumPagina]=useState(0);
  const auxCol=process.env.REACT_APP_Fondo_Color  
  useEffect(()=>{    
    //console.log(listAv);
    if (listAv.length!==0 &&listAv.secciones[0] ) 
    {       
      let aux={totHom:0, GenX:0, totMuj:0, avGen:0, MetaGen:0}      
      if (listAv.files.length>300) {
       
        let auxTam = listAv.files.lista.length;
        if(auxTam%300===0)
        {
            let pagina=Math.trunc(auxTam/300)
            setTotalPaginas(pagina)   
        }
        else
        {   let pagina=Math.trunc(auxTam/300)
            pagina=pagina+1;
            setTotalPaginas(pagina)
        }
        setNumPagina(0) 
        setDatos(listAv.files.slice(0,300))    
     
      }
      else{
        setTotalPaginas(1)
        setNumPagina(0)        
        setDatos(listAv.files)          
      }
      setTotalGen(aux) 
    }
    else
    { setDatos([])            
    }  
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAv])          

  const onChangeTable =(regis)=>{       
    setRegistro(regis)                           
  }

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline,estilo})=> celda(aline?aline:"center", {...estilo,backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  const onDownload = (response) => {              
    let a = document.createElement('a');
    a.href = response.link;
    a.download = response.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);   
    //URL.revokeObjectURL(response);       
  };

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >    
        
        <TableRow>
         <CelTitl index={1} titulo={ auxDatos.length } aline={"center"} estilo={{fontSize:"13px"}} />                    
          <CelTitl index={5} titulo={"Archivo"} aline={"center"} />                                   
        </TableRow>
                    
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.link === elemnto.link ? true:false                                              
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => onChangeTable(elemnto) }  
        >          
          {celda("left", {width:"2rem", fontSize:"12px"},   
          <IconButton onClick={()=>onDownload(elemnto)} size="small" >
            <Tooltip title="Descargar Excel">
              <SaveIcon style={{color:auxVal? 'white':""}} color={auxVal?"inherit":"primary"} fontSize="small" />
            </Tooltip>
            </IconButton>  ,index+2) }           
          {celda("left", {width:"20rem", fontSize:"12px"}, elemnto.name,index+5) }          
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {   setDatos(listAv[0].agrupamiento.slice(0,300))   
    }
    else
    {   setDatos(listAv[0].agrupamiento.slice(newPage * 300, newPage * 300 + 300))   
    }
    setNumPagina(newPage)
}
  function generarFooter(){
    return(
    <TablePagination  style={{padding:0}}
      rowsPerPageOptions={false}
      colSpan={3} rowsPerPage={300}
      count={listAv[0].agrupamiento.length} page={numPagina}
      lang='es'  onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}
      labelDisplayedRows={
        ({ from, to, count }) => {
        return '' + from + '-' + to + ' de ' + count
        }
      }
    />)
  }         

  const paginacion = totalPaginas>1 ? generarFooter() : null
  const llamada= datos.length !== 0 ?(TablaEstr(datos)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
                                                                                                                                           
  return (
  <Box display={'flex'} flexDirection="column" style={{height: '33rem' }}>
    
    <Card className={classes.estiloCard} style={{height:window.innerHeight-100}}>                        
      {paginacion}
      {/* <Typography variant="body1" component="body1"></Typography>  */}
      
      {llamada}   
    </Card>

  </Box>                    
  );
}