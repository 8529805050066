import React,{useEffect,useState} from "react";
import Modal from "../generales/ModalInformativo";
import { celda, TablaGeneral,celdaIcono, } from "../generales/TablaGeneral";
import { makeStyles } from "@material-ui/core/styles";
import {Box, TableRow,Card, LinearProgress, Button,IconButton,
  Tooltip,TablePagination} from '@material-ui/core';
import { ErrorGeneral,llamaApiCargaToken } from '../funciones/ClienteAxios';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import XLSX from 'xlsx';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";
import IntervaloSelect from "../perfiles/IntervaloTabla";
import SearchIcon from '@material-ui/icons/Search';
const useStyles = makeStyles({  
  container: {
    maxHeight: window.innerHeight - 252,
    minHeight: 280,    
    width: "30 rem",
  },
  container2: {
    maxHeight: 550,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_Color_Second,
    },
  },
  tableCell: {
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

const columnas = [
  {
    id: 1, titulo: "Fecha", alinear: "center",
  },
  
  {
    id: 2, titulo: "Nombre", alinear: "center",
  } 
  ,
  {
    id: 3, titulo: "Usuario", alinear: "center",
  } 
  ,
  {
    id: 4, titulo: "Teléfono", alinear: "center",
  } ,
  {
    id: 5, titulo: "Tipo", alinear: "center",
  } ,
  {
    id: 7, titulo: "Municipio", alinear: "center",
  },
];

export default function ModAltaReporte({modalAbierto, setModalAbierto, }) {
  const classes = useStyles();
  let history = useHistory();
  const tamMax=150
  const source = axios.CancelToken.source();
  const usuID = localStorage.getItem('UsuId');  
  const auxDispV =localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 ? localStorage.getItem('Dispvo'):'w8rf51v21dsd2cs'  
  const [selectedID] =  useState(null);
  const [listSecc,setListSecc] = useState([]);
  const [espera,setEspera]= useState(false);
  const [listaExcel, setListaExcel] = useState([]);  
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,seLlistPagina]=useState([]);
  const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});

  useEffect(() => {
      
    llenaListMeta()   

    return ()=> {
      source.cancel();
    }                
  }, []);

  const llenaListMeta=()=>{
    setListaExcel([])
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");
    const data = qs.stringify({
      Usuario:usuID,                                                  
      Fini:`${fInicio}T00:00:00`,
      Ffin:`${fFinal}T23:59:59`,
      idDispositivo: auxDispV,
    });
    function respuesta(auxiliar){
      if (auxiliar[0].SeccId !== -1) 
      {                      
        let arrM=[]
        arrM.push(["Fecha", "Nombre", "Usuario", "Teléfono", "Tipo", "Municipio" ]) 
        auxiliar.forEach(usua => {                          
          arrM.push([moment(usua.FAlta).format("YYYY-MM-DD"), usua.Nom, usua.Usr, usua.Tel, usua.Tipo, usua.Mun])
        }); 
      
        setListaExcel(arrM)       
        numeroPag(auxiliar,tamMax,setTotalPaginas,setNumPagina,seLlistPagina)   
        setListSecc(auxiliar)    
      }
      else{
        setListaExcel([])
      }                               
    }
    const url = "usuario-list-alta";
    llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
  }    
  
  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      seLlistPagina(listSecc.slice(0,tamMax))   
    }
    else
    {
      seLlistPagina(listSecc.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
}

  function generarFooter(){
    return(
    <TablePagination
      rowsPerPageOptions={false} style={{padding:0}}
      rowsPerPage={tamMax} count={listSecc.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listaExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista Alta Usuarios.xlsx")
  };

  function Renglon(props) {
    const { row } = props;

    return (
    <TableRow
      //onClick={() => { seleccionado(row.Id, row); }}
      className={classes.tableRow} selected={selectedID === row.Id}
      classes={{ hover: classes.hover, selected: classes.selected }}
    >     
      {celda( "center", { width: 60+ "px", fontSize: 12 + "px"},
        moment(row.FAlta).format("DD-MM-YYYY"), 1, classes.tableCell
      )}
      {celda( "left", { width: 200+ "px", fontSize: 12 + "px"},
        row.Nom, 2, classes.tableCell
      )}    
      {celda( "left", { width: 50+ "px", fontSize: 12 + "px"},
        row.Usr, 3, classes.tableCell
      )}    
      {celda( "center", { width: 70 + "px", fontSize: 12 + "px"},
        row.Tel, 4, classes.tableCell
      )}        
      {celda( "center", { width: 90 + "px", fontSize: 12 + "px"},
        row.Tipo, 5, classes.tableCell
      )}   
      {celda( "left", { width: 200 + "px", fontSize: 12 + "px"},
        row.Mun, 6, classes.tableCell
      )}                
    </TableRow>
    );
  }

  function cabeza(auxlista) {
    let estiloCol = { backgroundColor: process.env.REACT_APP_Fondo_Color, color: "white" };
    return (
    <TableRow>
      { columnas.map((row) => {
          if (row.id===1) 
          {          
            return( celda(row.alinear, estiloCol, `${auxlista.length} ${row.titulo}`, row.id,{}))
          }
          else{
            return( celda(row.alinear, estiloCol, row.titulo, row.id,{}))
          }
          })       
      }
    </TableRow>
    );
  }

  function Cuerpo(auxlista) {
    return auxlista.length !== 0
      ? auxlista.map((row) => <Renglon key={row.Id} row={row} />)
      : null;       
  }

  function tablaCompleta(auxlista) {      
    return (
    <Card>
     { TablaGeneral(Cuerpo(auxlista),cabeza(auxlista),classes.container)}
    </Card>
    );
  }

  const paginacion = totalPaginas>1 ? generarFooter() : null;

  const tabla=listPagina.length!==0   ? tablaCompleta(listPagina)  : null
  return (
  <Modal
    titulo={"Lista de Alta"} modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} tamanio={ "md"}      
  >
    <Box display={"flex"} flexDirection="column">        
      <Box display={"flex"}>
        <IconButton color="primary"  aria-label="add circle" 
            component="span" onClick={()=>llenaListMeta()}>
          <Tooltip title="Buscar">
            <SearchIcon/>
          </Tooltip>
        </IconButton>              
        <Box>
          <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
        </Box>  
        { usuID && parseInt(usuID) === 1 && listaExcel.length!==0 ?
        
          <IconButton onClick={exportarArch} size="small"    component="span" >
            <Tooltip title="Exportar a Excel">
              <SaveIcon color="primary" size="small" />
            </Tooltip>
          </IconButton>
          
        :null}              
      </Box>
        {paginacion}   
      {tabla}  
                       
    </Box>
    {espera?<Box m={1} style={{width:25+'rem'}}><LinearProgress/></Box>:null}
  </Modal>
  )
}
