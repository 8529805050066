import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Box,
    Collapse,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import qs from 'qs';
import moment from 'moment';
import {ErrorGeneral, llamadaApiToken} from '../funciones/ClienteAxios';
import ModalNvoEdit from './ModalNvoEdit';
import CreateIcon from '@material-ui/icons/Create';
const useStyles = makeStyles(theme=>({
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: theme.palette.secondary.main
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
}));

export default function TablaNotificacion({   history, selectedID, setSelectedID,listMun,
                                            source, idusu, buscar, checked, setRecorrido,
                                            actualiza,fchasSelect, 
                                        }) {
    const classes = useStyles();
    let filter = 0    
    const usuTipo = localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):null;
    const [espera, setEspera] = useState(false);
    const [datos, guardarDatos] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)
    const [open, setOpen] = useState(false);
    const [seleccionado, setSeleccionado] = useState([]);
    const [modalNvoEdit, setModalNvoEdit] = useState(false);
    
    useEffect(() => {
       
        llenarTabla();
    }, [buscar]);    

    useEffect(()=>{
        
        if (datos.length!==0 && selectedID) 
        {
            let nvo=[],cambio=false
            datos.forEach((dato)=>{
                if (dato.Id===selectedID.Id) {
                    if (dato.Avance!==selectedID.Avance) 
                    {
                        cambio=true
                        nvo.push(selectedID)
                    }
                }
                else
                {
                    nvo.push(dato)
                }
            })
            if (cambio) {
                guardarDatos(nvo)
            }
        }
    },[selectedID])

    const llenarTabla = () => {
        setEspera(true)
        if (checked) {
            filter = idusu
        } else {
            filter = 0
        }
        const fInicio = moment(fchasSelect.inicio).format("YYYY-MM-DD")
        const fFinal = moment(fchasSelect.final).format("YYYY-MM-DD")
        const data = qs.stringify({
            usuario: idusu,
            status:1,
            fini:`${fInicio}T00:00:00`,
            ffin:`${fFinal}T23:59:59`,
            idDispositivo: '45vf2d1vdf21d2f'
        });
        const url = "notificacion/v1/list";

        function response(data) {
            if (data[0].Id != -1) {
                guardarSinDatos(true)
                guardarDatos(data)
                setEspera(false)
            } else {
                guardarSinDatos(false)
                setEspera(false)
            }
        }

        llamadaApiToken(data, url, response, ErrorGeneral, history, source);
    };
    
    const seleccionar = ( auxOrden) => {
        setSelectedID(auxOrden)
        setRecorrido(auxOrden)
        setSeleccionado(auxOrden)
    }

    function verMas(auxId) {
        setSelectedID(auxId)
        if (auxId === selectedID) {
            setOpen(!open)
        } else {
            setOpen(false)
        }
    }

    const celda = (alinear, estilo, contenido, index) => {
        return (<TableCell align={alinear} className={classes.tableCell} 
            padding={'none'} style={estilo} key={index}
        >
            {contenido}
        </TableCell>)
    }

    
    const celdaEncabezado = (alinear, contenido,index) => {
        return (
        <TableCell align={alinear} className={classes.tableCell} padding={'none'} key={index}
            style={{backgroundColor: process.env.REACT_APP_Fondo_Color, color: "white"}}>
            {contenido}
        </TableCell>)
    }

    const obtenIcono=(cadena)=>{
        let icono="./iconos/"
        switch (cadena) {
            case "INFO":
                icono+="informacion.svg"
                break;
            case "WARNING":
                icono+="warning.svg"
                break;
            case "URGENT":
                icono+="urgent.svg"
                break;
            default:
                break;
        }
        return icono
    }
    
    function Row(props) {
        const {row} = props;
        const icono=<img src={obtenIcono(row.Icono)} style={{ width:"20px"}}/>  
            
            
        return (      
        <TableRow onClick={() => { seleccionar(row) }}
            className={classes.tableRow} selected={selectedID?.Id === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >               
            {celda("center", {fontSize: '12px',width:80},row.Id, 7) }    
            <TableCell align="center" padding={'none'} style={{width: 30}}>
                
                   {/*  <Tooltip title={'Editar Organización'}>
                   <IconButton color="primary" size="small"
                        onClick={() =>  setModalNvoEdit(true)}>
                        {icono}
                    </IconButton> 
                    
                    
                </Tooltip>
                    */}
             
                {icono}
            </TableCell> 
            {celda("center", {fontSize: '12px',width:40}, row.Total, 5) }      
            {celda("center", {fontSize: '12px',width:40}, row.Avance, 6) }      
            {celda("center", {fontSize: '12px',width:80}, moment.utc(row.Freg).format("YYYY-MM-DD HH:mm"), 2) }      
            {celda("left", {fontSize: '12px',width:180}, row.Titulo, 3) }      
            {celda("left", {fontSize: '12px',width:150}, row.UsrNom, 4) }                        
        </TableRow>
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>                                                
                        {celdaEncabezado("center","",7)}
                        {celdaEncabezado("center",auxlista.length,1)}                        
                        {celdaEncabezado("center", "Total",5)}     
                        {celdaEncabezado("center", "Avance",6)}     
                        {celdaEncabezado("center", "Fecha",2)}
                        {celdaEncabezado("center","Titulo" ,3)}
                        {celdaEncabezado("center", "Capturo",4)}                                                                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {auxlista.length != 0 ? (auxlista.map((row) => (
                        <Row key={row.Id} row={row}/>
                    ))) : null}
                    
                </TableBody>
            </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
        <TableContainer className={classes.container2}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>                        
                        {celdaEncabezado("center", "Fecha",2)}
                        {celdaEncabezado("center", "Tipo",3)}
                        {celdaEncabezado("center", "Alias",4)}                                               
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                        <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                        <TableCell className={classes.tableCell} align="left">Recorridos</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        )
    }

    const contenido = sinDatos && datos.length != 0 ? tablaCompleta(datos) : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)
    const modNvo =modalNvoEdit ? <ModalNvoEdit abrirModal={modalNvoEdit} setAbrirModal={setModalNvoEdit} 
                                    registro={selectedID} listMun={listMun} actualiza={actualiza}
                                    titulo={`Editar Organización ${selectedID.Id}`}                                  
                                />:null
    return (
    <Paper>
        {tabla}
        {modNvo}
    </Paper>
    );
}