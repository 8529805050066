import Modal from '../generales/Modal'
import {Grid, TextField, MenuItem, makeStyles, Box, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import moment from "moment";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import axios from "axios";
import qs from "qs";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ModalSugerencia({
                                            seleccionado,
                                            setModalAbierto,
                                            modalAbierto,
                                            titulo,
                                            listStatus,
                                            listTipo,
                                            actualizar,
                                            setActualizar
                                        }) {

    const [type, setType] = useState(seleccionado.TipoId);
    const [status, setStatus] = useState(seleccionado.StatusId);
    const [btnDeshabilitado, setBtnDeshabilitado] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [aviso, setAviso] = useState(false);
    const [aux, setAux] = useState(0);
    const [aux2, setAux2] = useState(0);

    const useStyles = makeStyles((theme) => ({
        margin: {
            margin: theme.spacing(1),
        }, root: {
            '& > svg': {
                fontSize: 18
            }, color: 'gray'
        }, h3: {
            color: 'rgb(15, 130, 238)', margin: 0
        }, textGray: {
            textAlign: 'justify'
        }
    }));
    const classes = useStyles();

    let Dispvo = localStorage.getItem('Dispvo');
    let auxDispV = Dispvo.length >= 12 ? Dispvo : 'w8rf51v21dsd2cs'
    let usuID = localStorage.getItem('UsuId');
    let token = localStorage.getItem('token20');
    const source = axios.CancelToken.source();

    let data = qs.stringify({
        usuario: usuID,
        solicitud: seleccionado.Id,
        tipo: type,
        idDispositivo: auxDispV,
    });

    let data2 = qs.stringify({
        usuario: usuID,
        solicitud: seleccionado.Id,
        status: status,
        idDispositivo: auxDispV,
    });

    const urlType = 'sugerencia-publico-edit-tipo'
    const urlStatus = 'sugerencia-publico-edit-status'

    let configType = {
        method: "post",
        url: process.env.REACT_APP_LINK + urlType,
        headers: {
            'access-token': token,
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
        cancelToken: source.token,
    };

    let configStatus = {
        method: "post",
        url: process.env.REACT_APP_LINK + urlStatus,
        headers: {
            'access-token': token,
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data2,
        cancelToken: source.token,
    };

    useEffect(() => {
        return () => {
            if (type === listTipo[0]) {
                setBtnDeshabilitado(true);
            } else {
                setBtnDeshabilitado(false);
            }
        };
    }, [type]);

    useEffect(() => {
        return () => {
            if (status === listStatus[0]) {
                setBtnDeshabilitado(true);
            } else {
                setBtnDeshabilitado(false);
            }
        };
    }, [status]);


    const handleChangeTipo = (event) => {
        setType(event.target.value);
        setAux(1)
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
        setAux2(1)
    };

    const editarRegistro = async () => {
        setCargando(true)
        if (aux === 1) {
            await axios(configType)
                .then(function (response) {
                    toast.success('Categoría editada correctamente', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .catch(function (error) {
                    toast.error('Ocurrio un error, intentelo mas tarde', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }

        if (aux2 === 1) {
            await axios(configStatus)
                .then(function (response) {
                    toast.success('Estatus editado correctamente', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .catch(function (error) {
                    toast.error('Ocurrio un error, intentelo mas tarde', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
        setCargando(false)
        setModalAbierto(false);
        setActualizar(!actualizar);
    }

    function guardarDatos(e) {
        e.preventDefault()
        try {
            editarRegistro();
        } catch (error) {
            toast.error('Ocurrio un error, intentelo mas tarde', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (<Modal titulo={titulo}
                   modalAbierto={modalAbierto}
                   setModalAbierto={setModalAbierto}
                   guardarDatos={guardarDatos}
                   mensaje={''}
                   tipoAdvertencia={''}
                   aviso={aviso}
                   setAviso={setAviso}
                   tamanio='sm'
                   btnDeshabilitado={btnDeshabilitado}
                   cargando={cargando}
                   subtitulo={moment.utc(seleccionado.Fecha).format('DD/MM/YYYY HH:mm') + '\n' + seleccionado.MunNom}
    >
        <div className={classes.margin}>
            <Grid container spacing={1} alignItems='center' justifyContent='center'>
                <Grid item md={8}>
                    <p className={classes.textGray}>{seleccionado.Mensaje}</p>
                    <Grid container>
                        {seleccionado.Email ? <>
                            <Grid item md={1} className={classes.root}>
                                <MailOutlineIcon color="primary"/>
                            </Grid>
                            <Grid item md={11} className={classes.textGray}>
                                {seleccionado.Email}
                            </Grid>
                        </> : <></>}
                        {seleccionado.Tel ? <>
                            <Grid item md={1} className={classes.root}>
                                <PhoneIcon color="primary"/>
                            </Grid>
                            <Grid item md={11} className={classes.textGray}>
                                {seleccionado.Tel}
                            </Grid>
                        </> : <></>}
                    </Grid>
                </Grid>
                <Grid item md={4}>
                    <Grid container spacing={1}>
                        <Grid item md={12} className={classes.margin}>
                            <TextField
                                id="select-tipo"
                                select
                                fullWidth
                                label="Categoría"
                                value={type}
                                onChange={handleChangeTipo}
                            >
                                {listTipo.map((tipo) => (
                                    <MenuItem key={tipo.Id} value={tipo.Id}>
                                        {tipo.Nom}
                                    </MenuItem>))}
                            </TextField>
                        </Grid>
                        <Grid item md={12} className={classes.margin}>
                            <TextField
                                id="select-status"
                                select
                                fullWidth
                                label="Estatus"
                                value={status}
                                onChange={handleChangeStatus}
                            >
                                {listStatus.map((status) => (
                                    <MenuItem key={status.Id} value={status.Id}>
                                        {status.Nom}
                                    </MenuItem>))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </Modal>)
}