import React,{useEffect,useState} from 'react';
import {HorizontalBar,Bar} from 'react-chartjs-2';
import {Card,CardContent,Box, Typography} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    //paddingRight:'3rem'
    height:10+'rem',marginRight:".3rem"
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    //color:'#4a4a4a',
  },
  tituloGraf:{
    paddingTop:'0rem',
    textAlign:'center'
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function Graficas({valor}){
  const classes = useStyles();
  const color1= '#19A11E';  //ff1744  
  const color2= '#FBBE06';
  const auxColor=process.env.REACT_APP_Fondo_Color
  const [data,setData]= useState([]);
  const [bandera,setBandera]= useState(false);
  useEffect(()=>{    
    const{ TotBarda, TotLona, 
      TotEspectacular, TotOtros,
    }=valor[0]            
    
    
    setData({TotBarda:TotBarda, TotLona:TotLona, 
      TotEspectacular:TotEspectacular, TotOtros:TotOtros,})
    setBandera(true)
  },[valor])

  const dataGenero = {
    labels: [''],
    datasets: [
    { type: 'bar', label:[ 'Barda' ],
        data:[data.TotBarda],//[10, 20, 30, 40],
        backgroundColor:  [ "#4BAC7D50"]//'rgba(255, 99, 132, 0.2)',         
      },              
      { label:'Lona',
        type: 'bar',
        data: [data.TotLona], //[50, 40, 30, 50],
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',
        //fill: false,
        tension: 0.4           
      },
      { label:'Espectacular',
        type: 'bar',
        data: [data.TotEspectacular], //[50, 40, 30, 50],
        backgroundColor:'#C52363',
        borderColor: '#C52363',
        //fill: false,
        tension: 0.4   
        
      },
      { label:'Otros',
      type: 'bar',
      data: [data.TotOtros], //[50, 40, 30, 50],
      backgroundColor:'#7C594C',
      borderColor: '#7C594C',
      //fill: false,
      tension: 0.4   
      
    },     
    ],
  }; 

  const dataRecor = {
    labels: ["Barda", "Lona", "Espectacular", "Otros"],
    datasets: [{
      data: [data.TotBarda, data.TotLona, data.TotEspectacular, data.TotOtros ],
      backgroundColor: ["#4BAC7D50","#4BAC7D","#C52363", "#7C594C" ],
      hoverBackgroundColor: [
        color1,
        color2
      ]
    }]
  }; 

  const options = {
  
  //  indexAxis:'y',
  plugins: {
    legend: {
        display: false
    }
  },
    tooltips: {
      enabled: true,
    },
    indexAxis:'y',
    title:{
      fontStyle: 'bold',
    },
    scales: {
      x: {
          display: true,
          title: {
          display: true
          }
      },
      y: {
          display: true,
          title: {
          display: false,
       
          },
       
      },
      }
  } 

  function  llenadoTabla2(datos)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
      ,paddingBottom:.5+'rem',height:10+'rem',width:25+'rem'}} >              
      <Bar
        id="graficaMuni"
        data={datos}
        width={400} height={200} 
        options={options}
      />   
    </Card>
    );
  }

  return (
  <Box display="flex" flexDirection="row" justifyContent={'center'} mb={1} style={{marginTop:1+'rem'}} >           
     <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataRecor):null}
      </Box>      
    {/*   <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataEdad,process.env.REACT_APP_Promov):null}
      </Box>  */}    
  </Box>
  );
}