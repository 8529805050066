import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card,Box, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
const useStyles = makeStyles({
    estiloCard:{
      width: '83rem',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      // color:'#4a4a4a',
      display:'block',
      padding:'.5rem',
      maxHeight:'100%',
      minHeight: 280,
      marginRight:'.5rem'
    },
    tablaContainer: {
      width: '82rem',
      maxHeight: '26.5rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    totalEstilo:{
      fontSize:'1.25rem',
      fontWeight:'bold'
    },
    tituloEstilo:{
      paddingBottom:'.25rem',
      fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

 

export default function TablaPromovidos({listAv}){    
  const classes = useStyles();      
  const [datos,setDatos] = useState([]);     
  const [registro,setRegistro] = useState([]);     

  const auxCol=process.env.REACT_APP_Fondo_Color  
  useEffect(()=>{    
    //console.log(listAv);
    if (listAv.length!==0 &&listAv && listAv[0].keyId!=="0") 
    {  
      setDatos(listAv)  
   
    }
    else
    { setDatos([])
    }  
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAv])          

 /*  const onChangeTable =(regis)=>{       
    setRegistro(regis)                           
  } */

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline,estilo})=> celda(aline?aline:"center", {...estilo,backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  const colorStatus=(status)=>{
    let stdColor=""
    if (status<3) 
    { stdColor="#E91E63"      
    }
    else
    { if (status<5) 
      {
        stdColor="#f07111"      
      }
      else
      {
        if (status<7) 
        { stdColor="#FBBE06"      
        }
        else
        { stdColor="#19A11E"                        
        }         
      } 
    }
    return(
    <Box display={"flex"}  justifyContent="flex-end" style={{textAlign:"right",alignItems:"center",fontSize:"12px"}}>            
      {`${status}`}
      <FiberManualRecordIcon fontSize="small" style={{color:stdColor,}}/>      
    </Box>)
  }  

  const formatoNom=(registro)=>{
    const respuesta=`${registro.Nom} ${registro.Pat} ${registro.Mat}`
    return primerformato(respuesta)
  }

  const primerformato = str => {
    return str.toLowerCase().replace(/\b[a-z]/g, c => c.toUpperCase())
  }

  const obtenerGen=(aux)=>{
    let respuesta=""
    switch (aux) {
      case 'H':
        respuesta="Hombre"
        break;
      case 'M':
        respuesta="Mujer"
        break;
      case 'X':
        respuesta="No Binario"
        break;
      case 'N':
        respuesta="Pendiente"
        break;
      default:
        break;
    }
    return respuesta
  }

  const formato=(campo,leyenda)=>{
    if (leyenda!=="") {
      return campo!==""? `${leyenda} ${campo}`:''
    }else{
      return campo!==""?`${campo}, `:''
    }
    
  }
  const obtnDir=(regis)=>{
    const {Col,Calle,NoExt,NoInt}=regis
    return`${formato(Col,"")} ${Calle} ${formato(NoExt,"NoExt")} ${formato(NoInt,"NoInt")}`
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >            
        <TableRow>         
        <CelTitl index={4} titulo={"INE"} aline={"center"} />         
          <CelTitl index={2} titulo={"Nombre " } aline={"center"} estilo={{fontSize:"13px"}} />                                              
          <CelTitl index={2} titulo={"Municipio" } aline={"center"} estilo={{fontSize:"13px"}} />
          <CelTitl index={2} titulo={"Sección" } aline={"center"} estilo={{fontSize:"13px"}} />
          <CelTitl index={2} titulo={"Genero" } aline={"center"} estilo={{fontSize:"13px"}} />           
          <CelTitl index={2} titulo={"F. Nac" } aline={"center"} estilo={{fontSize:"13px"}} />                                    
          <CelTitl index={5} titulo={"Dirección"} estilo={{fontSize:"13px"}} aline={"center"} />                   
          {/* <CelTitl index={5} titulo={"Télefono"} estilo={{fontSize:"13px"}} aline={"center"} />                    */}
        </TableRow>        
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.keyId === elemnto?.keyId ?true:false       
        const auxNac=moment.utc( elemnto.Fnac).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
        ? "" : moment.utc( elemnto.Fnac).format('DD/MM/YYYY')                                           
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => {} }  
        > 
          {celda("center", {width:"8rem", fontSize:"11px",paddingRight:".5rem"},elemnto.Ine ,index) }            
          {celda("left", {width:"10rem", fontSize:"12px",marginRight:".2rem"}, formatoNom(elemnto) ,index+1) }      
          {celda("left", {width:"10rem", fontSize:"12px",marginRight:".2rem"}, elemnto.Muni ,index+2) }                    
          {celda("center", {width:"3rem", fontSize:"12px",marginRight:".2rem"}, elemnto.Secc ,index+3) }                    
          {celda("center", {width:"4rem", fontSize:"12px",marginRight:".2rem"}, obtenerGen(elemnto.Gen) ,index+3) }                    
          {celda("center", {width:"4rem", fontSize:"12px",marginRight:".2rem"}, auxNac ,index+3) }                    
          {celda("center", {width:"20rem", fontSize:"12px"}, obtnDir(elemnto),index+5) }
          {/* celda("right", {width:"2.4rem", fontSize:"12px"}, elemnto.Tel,index+6)  */}          
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
                       
  const llamada= datos.length !== 0 ?(TablaEstr(datos)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
                                                                                                                                           
  return (
  <Box display={'flex'} flexDirection="row" style={{height: '26rem' }}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-140}}>                        
      {/* <Typography variant="body1" component="body1"></Typography>  */}
      {llamada}   
    </Card>

  </Box>                    
  );
}