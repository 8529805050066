import {makeStyles} from "@material-ui/core/styles";
import {Card, FormLabel, Grid, TextField, Typography} from "@material-ui/core"
import {useEffect, useState} from "react";
import SelectStatus from "./SelectStatus";

const useStyles = makeStyles((theme) => ({
    statusCard: {
        textAlign: 'center',
        marginRight: 5, height: "100%", paddingLeft: .5 + 'rem', paddingRight: .5 + 'rem', width: '6.4rem',
    }, marginBottom: {
        marginBottom: theme.spacing(1)
    }
}));

const CardEditarApoyo = ({index, element, setBotonDeshabilitado, bandera, setListMod, listMod, listOrig}) => {

    const classes = useStyles();
    const [apoyo, setApoyo] = useState({
        Id: 0,
        Fch: "",
        UsrId: 0,
        UsrNom: "",
        StatusId: 0,
        StatusNom: "",
        TipoId: 0,
        TipoNom: "",
        Cant: 1,
        FAlta: "",
        Observ: ""
    })

    const {
        Id, Fch, Cant, FAlta, Observ, StatusId, StatusNom, TipoId, TipoNom, UsrNom, UsrId
    } = apoyo

    useEffect(() => {
        let filtrado = listOrig.filter((auxApoyo) => auxApoyo.TipoId === element.Id)

        if (filtrado.length !== 0) {
            let arr = filtrado[0]
            setApoyo({
                Id: arr.Id,
                Fch: arr.Fch,
                UsrId: arr.UsrId,
                UsrNom: arr.UsrNom,
                StatusId: arr.StatusId,
                StatusNom: arr.StatusNom,
                TipoId: arr.TipoId,
                TipoNom: arr.TipoNom,
                Cant: arr.Cant,
                FAlta: arr.FAlta,
                Observ: arr.Observ
            })
        } else {
            setApoyo({
                ...apoyo, TipoId: element.Id, TipoNom: element.Descr,
            })
        }

    }, [])

    useEffect(() => {
        if (bandera) {
            let nvo = listMod
            nvo.push(apoyo)
            return setListMod(nvo)

        }

    }, [bandera])

    const onChangeNumTel = e => {
        e.preventDefault();
        setBotonDeshabilitado(false)
        let expNopermitida = new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');
        let expCadena = new RegExp('[.0-9$]');
        if (e.target.value.length <= 10 && (expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) || e.target.value === ' ' || e.target.value === '') {
            setApoyo({
                ...apoyo, Cant: e.target.value
            })
        }

    };

    const handleObservacion = (event) => {
        event.preventDefault();

        setBotonDeshabilitado(false)
        setApoyo({
            ...apoyo, Observ: event.target.value
        });
    }

    const stdColor = apoyo.StatusId === 1 ? '#F46239' : (apoyo.StatusId === 2 ? '#04cd1f' : '#eeeeee')

    return (<div key={index} style={{overflowY: 'auto', padding: 0}} id={"cardDetPil" + index}>
        <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
                {index === 0 ? <FormLabel component="legend">
                    <small><b>Estatus</b></small> </FormLabel> : null}
            </Grid>
            <Grid item style={{marginLeft: 8}}>
                {index === 0 ? <FormLabel component="legend">
                    <small><b>Tipo </b></small> </FormLabel> : null}
            </Grid>
        </Grid>
        <Card className={classes.marginBottom} style={{padding: 0}}>
            <Grid container alignItems={'center'} style={{padding: 0}}>
                <Grid item>
                    <Card className={classes.statusCard}
                          style={{
                              backgroundColor: stdColor, alignItems: "center", display: 'flex', padding: '0.625rem',
                          }}
                    >
                        <Typography variant="body1" style={{
                            fontWeight: 600,
                            fontSize: 14 + 'px',
                            color: (StatusId === 1 || StatusId === 2 ? 'white' : '')
                        }}>
                            {apoyo.StatusId === 1 ? 'Pendiente' : (apoyo.StatusId === 2 ? 'Entregado' : 'Sin asignar')}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={2}>
                        <Grid item style={{marginTop: ".6rem",}}>
                            <SelectStatus index={index} apoyo={apoyo} setApoyo={setApoyo}
                                          setBotonDeshabilitado={setBotonDeshabilitado}/>
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'left'}}>
                            <FormLabel component="legend" style={index === 0 ? {marginTop: 10} : {marginTop: 15}}>
                                {TipoNom}</FormLabel>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField label={'Cant.'} value={Cant} name="Cant" size="small"
                                       onChange={onChangeNumTel}></TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label={'Observación'} value={Observ} name="Observ" style={{width: 100 + '%'}}
                                       size="small"
                                       onChange={handleObservacion}></TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    </div>);
};

export default CardEditarApoyo;
