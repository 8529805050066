import {useEffect, useState,useContext} from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import { Grid, CircularProgress} from "@material-ui/core";
import TablaCache from "./TablaCache";
import axios from "axios";
import {GeneralesContext} from '../context/GeneralesContext'
import { AuthContext } from "../context/AuthContext";

import { authUser } from '../funciones/AuthUser'
const useStyles = makeStyles((theme) => ({}));

const arrBase={
    "Id": 0, "MunId": 0, "MunNom": "TODOS",
    "MunNum": 0, "UsrId": 0, "UsrNom": "",
    "UsrTel": "0","UsrUsr":""
  }

const LimpiaCache = () => {
    const classes = useStyles();
    const usu = localStorage.getItem('UsuarioNom');                
    const usuID = localStorage.getItem('UsuId');   
    const nvl= localStorage.getItem('Tipo');         
    let history = useHistory();
    const source = axios.CancelToken.source();
    const {generales,guardarGenerales}=useContext(GeneralesContext); 
    const {usuario,guardarUsuario}=useContext(AuthContext);
    const [espera, setEspera] = useState(false);
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({
        inicio: new Date(), final: new Date(), });

    const [selectedID, setSelectedID] = useState(null);    
    const [prospecto, setProspecto] = useState([]);
    const [galeria, setGaleria] = useState([]);
    const [latitud, setLatitud] = useState(0);
    const [longitud, setLongitud] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [abrirModalMapa, setAbrirModalMapa] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [buscar, setBuscar] = useState(true);
    const [checked, setChecked] = useState(false);
    const [encargado,setEncargado]=useState([])
    useEffect(() => {
        
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
                ||(usuID===undefined||usuID===''||usuID===null) )
            {   guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {   
                if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                         
                    
                }                                                       
                guardarGenerales({...generales,Titulo:'Cache'})  
                                                                  
                
              
            }   
        }

        veriSesion()
    }, []);


    const buscarDatos = () => {
        setBuscar(!buscar)
    }

 
 
    const principal = () => {
        return (
            <Grid container spacing={1}>
             
                <Grid item xs={3}>
                    <TablaCache
                        history={history} setEspera={setEspera}
                        source={source} idusu={usuID}       
                                                                                      
                    />
                </Grid>
                {espera? <Grid item xs={3} style={{marginTop:"1rem",marginLeft:"1rem"}}>
                    <CircularProgress/>    
                </Grid> :null   }    
            </Grid>
        );
    };

    return <div>{principal()}</div>;
};

export default LimpiaCache;
