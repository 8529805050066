import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useRef, useState} from "react";
import {Button, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar} from "@material-ui/core";
import axios from "axios";
import {toast} from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import {v4 as uuidv4} from 'uuid';
import Progress from "../generales/Progress";
import {ErrorGeneral, llamaApiFormData} from '../funciones/ClienteAxios';
import {useHistory} from "react-router-dom";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    }, imageList: {
        maxHeight: 450, maxWidth: 500
    }, icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    }, "@global": {
        '#form-file-upload': {
            textAlign: 'center', position: 'relative', width: 550
        }, '#input-file-upload': {
            display: 'none',
        // }, '#label-file-upload': {
        //     height: '100%',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     borderWidth: '2px',
        //     borderRadius: '1rem',
        //     borderStyle: 'dashed',
        //     borderColor: theme.palette.secondary.main,
        //     paddingTop: 25,
        //     paddingBottom: 25
        },
        'upload-button': {
            cursor: 'pointer',
            padding: '0.25rem',
            fontSize: '1rem',
            border: 'none',
            fontFamily: 'Oswald',
            backgroundColor: 'transparent'
        }, 'upload-button:hover': {
            textDecorationLine: 'underline'
        }, '#label-file-upload.drag-active': {
            backgroundColor:process.env.REACT_APP_Color_Second, color: 'white'
        }, '#drag-file-element': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '1rem',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
}));

const ModalAgregarEvidencia = ({abrirModal, setAbrirModal, selectedID, idusu, setUpdate,recorrido}) => {

    const classes = useStyles();
    const inputRef = useRef(null);
    let history = useHistory();
    const source = axios.CancelToken.source();
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(true)
    const [aviso, setAviso] = useState(false);
    const [espera, setEspera] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [size, setSize] = useState(0);
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [dragActive, setDragActive] = useState(false);
    const [arraySubidos, setArraySubidos] = useState([])
    const [arrayPreview, setArrayPreview] = useState([])
    const formData = new FormData();
    const [progresoEnvio, setProgresoEnvio] = useState(0);
    const [fecha, setFecha] = useState(moment.utc(new Date).format("DD-MM-YYYY"));    
    
    useEffect(() => {
       if (recorrido.length!==0) 
       {    
            setFecha( moment.utc(recorrido.FReg).format("DD-MM-YYYY"))
       }
    
       console.log(recorrido);
    }, [ ])
    
    useEffect(() => {
        if (arraySubidos.length !== 0) {
            setBotonDeshabilitado(false)
        } else {
            setBotonDeshabilitado(true)
        }
        let aux = arraySubidos.reduce((x, y) => x + y.size, 0)
        setSize(aux)
    }, [arraySubidos]);

    const handleChange = function (event) {
        const regex = /([a-zA-Z0-9\s_.\-():])+(.jpg|.jpeg|png)$/
        let image = event.target.files[0]
        let auxCode = uuidv4();
        if (image && image.name.toLowerCase().match(regex)) {
            let auxNombre = auxCode + image.name.slice(image.name.lastIndexOf("."), image.name.length);
            let myNewFile = new File([image], auxNombre, {type: image.type});
            setArraySubidos([...arraySubidos, myNewFile])
            setArrayPreview([...arrayPreview, URL.createObjectURL(image)])
        } else {
            let nombre = "";
            if (image.name.length >= 25) {
                nombre = image.name.substring(0, 24) + "..." + image.name.substring(image.name.lastIndexOf("."), image.name.length);
            } else {
                nombre = image.name;
            }
            let mensaje = `Formato de archivo incorrecto en ${nombre} seleccione una imagen tipo jpg,jpeg o png.`
            toast.warning(mensaje, {
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `incorrecto-sel`
            });
        }
    }

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const removeElement = (index) => {
        let newArr = arraySubidos.filter(element => element !== arraySubidos[index]);
        let newArr2 = arrayPreview.filter(element => element !== arrayPreview[index]);
        setArraySubidos(newArr)
        setArrayPreview(newArr2)
    };

    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }


    const guardarImagenes = () => {
        const llenarPeticion = () => {
            setEspera(true)
            setBotonDeshabilitado(true)
            formData.append('Llave', selectedID)
            formData.append('Usuario', idusu)
            formData.append('Descr', 'Envio de Prueba')
            formData.append('idDispositivo', '45vf2d1vdf21d2f')
            arraySubidos.forEach((img) => {
                formData.append('multi-files', img)
            })

            let url = 'recorrido-file';

            function respuesta(response) {
                if (response.success === true) {
                    setProgresoEnvio(0)
                    setAviso(true)
                    setEspera(true)
                    setBotonDeshabilitado(true)
                    toast.success('Imagenes de evidencia agregadas correctamente', {
                        pauseOnFocusLoss: false,
                        toastId: `nvo-evidencia${1}`
                    })
                    setAbrirModal(false)
                    setUpdate(true)
                } else {
                    setBotonDeshabilitado(false);
                    setEspera(false)
                    toast.error('Datos incorrectos, intente mas tarde', {
                        pauseOnFocusLoss: false,
                        toastId: `error-recorrido${1}`
                    })
                    setAbrirModal(false)
                }
            }

            llamaApiFormData(formData, url, respuesta, ErrorGeneral, setEspera, history, source, size, setProgresoEnvio);
        }
        llenarPeticion()
    }

    // size: size.toString(),
    //     estado: process.env.REACT_APP_EDO,
    //     tipo: 'SPACE',
    //     clase: 'RECORRIDO',
    //     'access-token': localStorage.getItem('token20'),
    //     "Content-Type": "application/json",
    //

    function guardarDatos(e) {
        e.preventDefault()

        if (arraySubidos.length !== 0) {
            guardarImagenes();
        } else {
            toast.warning(`Debe seleccionar una imagen para subir`, {
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `incorrecto-sel`
            });
        }
    }

    function showImages() {
        return <Grid item xs={12}>
            <ImageList className={classes.imageList}>
                {arraySubidos.map((imagen, index) => <ImageListItem key={index}>
                    <img src={arrayPreview[index]} alt={imagen.name}/>
                    <ImageListItemBar
                        title={imagen.name}
                        actionIcon={<IconButton aria-label={`${imagen.name}`}
                                                className={classes.icon} onClick={() => removeElement(index)}>
                            <CloseIcon/>
                        </IconButton>}
                    />
                </ImageListItem>)}
            </ImageList>
        </Grid>
    }

    function uploadFiles() {
        return <Grid item >
            <form id="form-file-upload">
                <input ref={inputRef} type="file" accept="image/*" id="input-file-upload" multiple
                       onChange={handleChange}/>
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                    {arraySubidos.length < 5 ? <div>
                        {/*<p>Arrastra y suelta tus archivos o</p>*/}
                        <Button color={'primary'} variant={'contained'} className="upload-button"
                                disabled={espera ? true : false}
                                onClick={onButtonClick}>BUSCAR ARCHIVOS</Button>
                    </div> : null}
                </label>
            </form>
        </Grid>
    }

    return (<Modal
        titulo={'Subir Evidencia ' + fecha} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={espera} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
            {arraySubidos.length < 5 ? uploadFiles() : null}
            {arrayPreview.length !== 0 ? showImages() : null}
            {progresoEnvio > 0 ? (
                    <Progress progress={progresoEnvio}/>)
                : null}
        </Grid>

    </Modal>);
};

export default ModalAgregarEvidencia;