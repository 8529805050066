import React, { useState } from 'react';
import { Box, Typography, Card, Button, CircularProgress, Grid, DialogContent, DialogActions } from '@material-ui/core';
import ModalInformativo from '../generales/ModalInformativoStd2';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { green, red } from '@material-ui/core/colors';
import axios from 'axios';
import qs from 'qs';
const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        margin: 'auto',
        padding: 0,
        minWidth: 35 + 'rem'
    },
    root2: {
        alignItems: 'center',
        margin: 'auto',
        padding: 0,
        minWidth: 35 + 'rem'
    },
    cardImagen: {
        width: '95%',
        height: '90%',
        marginRight: theme.spacing(2),
        position: 'relative',
        alignContent: 'center'
    },
    noDisponible: {
        fontSize: '1rem',
        fontWeight: 600,
        textAlign: 'center',
        paddingTop: '3rem',
        paddingBottom: '3rem',
        marginRight: theme.spacing(2),
    },
    cardDatos: {
        marginBottom: .5 + 'rem',
        position: 'relative'
    },
    imgContent: {
        padding: 0,
        marginRight: theme.spacing(2),
        overflow: 'hidden'
    },
    imagen: {
        width: '100%',
        height: '23rem'
    },
    wrapper: {
        marginTop: 0,
        paddingTop: 0,
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonGuardar:{color: green[500],},
    buttonRechazar:{color: red[500],},
    margin: {
        margin: theme.spacing(2),
    },
}));

export default function ModInfoPublico({ seleccionado, setModalAbierto, modalAbierto, titulo }) {
    const classes = useStyles();
    let history = useHistory();
    //console.log(seleccionado);
    const [btnOpcion, setBtnOpcion] = useState(0);
    const [aviso, setAviso] = React.useState(false);
    const [mensaje, setMensaje] = React.useState("");
    const source = axios.CancelToken.source();
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState("success");
    const [espera, setEspera] = React.useState(false);
    const [btnDeshabilitado, setBtnDeshabilitado] = React.useState(false);
    const [sinImg, setSinImg] = React.useState(false);

    let fecha = moment.utc(seleccionado.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? ""
        : moment.utc(seleccionado.Fecha).format('DD/MM/YYYY HH:mm')

    React.useEffect(() => {
        if (seleccionado?.Imagen !== undefined && seleccionado.Imagen !== ""
            && seleccionado.Imagen.length > 0) {
            setSinImg(true)
        }

    }, [])

    const cambiarStatus = (e, auxValor) => {
        e.preventDefault()
        //console.log(auxValor);
        llamadaNvoStd(seleccionado.Id, auxValor)
        setBtnOpcion(auxValor)
    }

    function tipoEdoProspecto(auxColorStd, etiqueta) {
        let renglon = (
            <Card style={{
                color: auxColorStd === "#EBF1F8" ? "black" : 'white', fontWeight: "bold",
                backgroundColor: auxColorStd, textAlign: 'center'
            }}>
                {etiqueta}
            </Card>
        )
        return renglon
    }

    async function llamadaNvoStd(auxPerfil, auxStd) {
        setEspera(true);
        let auxU = process.env.REACT_APP_LINK + `perfil-publico-validar`;
        let token = localStorage.getItem('token20');
        let usuId = localStorage.getItem('UsuId');
        let dataInfo = qs.stringify({
            'Usuario': usuId,
            'Perfil': auxPerfil,
            'Status': auxStd,
            'idDispositivo': '451224452121'
        });
        let config = {
            url: auxU,
            method: 'POST',
            headers: { 'access-token': token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux = {};
        await axios(config)
            .then(function (response) {
                aux = response.data;
                console.log(aux)
                if (aux.length !== 0) {
                    //setNvoRegistro(aux[0]);               
                    // setBtnDeshabilitado(true)                                  
                    let mensaje = "Se actualizo el Estatus"
                    setBtnDeshabilitado(true)
                    setTipoAdvertencia("success")
                    setMensaje(mensaje);
                    setAviso(true)
                }
            })
            .catch(function (error) {
                console.log(`Error: ${error}`);
                if (error && error.response && error.response.data && error.response.data.mensaje === "Datos Incorrectos") {
                    console.log('datos incorrectos');
                } else {
                    if ((error && error.response && error.response.data && error.response.data.error.name === "JsonWebTokenError") ||
                        (error && error.response && error.response.data && error.response.data.error.name === "TokenExpiredError")) {
                        console.log('loguearse de nuevo');
                        localStorage.clear();
                        history.push("/");
                    }
                }
            });
        setEspera(false);
    };

    const cardPerfil1 = (auxEstilo) => (
        <Box display={"flex"} flexDirection={"row"}>
            <Box className={classes.root} >
                <Card className={classes.cardDatos}>
                    <Box display={"flex"} flexDirection={"column"} style={auxEstilo}>
                        {   seleccionado.Nombre ?
                            <Box display={"flex"} flexDirection={"row"} style={{ minWidth: 18 + 'rem', maxWidth: 22 + 'rem' }}>
                                <Box display={"flex"}   >
                                    <Typography color="primary" variant="body2" style={{ fontWeight: 600, marginRight: '.1rem', width: 8 + 'rem', marginTop: .2 + 'rem' }}>
                                        {fecha}
                                    </Typography>
                                </Box>
                                <Box flexWrap="wrap" >
                                    <Typography variant="body2" style={{ fontWeight: 650, minWidth: 10 + 'rem', maxWidth: 20 + 'rem', minHeight: 2 + 'rem', marginTop: .2 + 'rem' }}>
                                        {seleccionado.Nombre}
                                    </Typography>
                                </Box>
                                <br />
                            </Box>
                            : null}
                        <Box >
                            <Typography> {' ' + seleccionado.Mensaje} </Typography>
                        </Box>
                    </Box>
                </Card>
                <Card className={classes.cardImagen} style={{ marginBottom: '.3em', alignContent: 'center' }}>
                {   seleccionado?.Imagen !== undefined && seleccionado.Imagen !== "" ?
                    <img src={seleccionado.Imagen} className={classes.imagen} alt="foto" />
                :   <Typography className={classes.noDisponible}> Sin foto disponible </Typography>
                }
                </Card>
            </Box>
            {seleccionado.StatusId === 1 ?
                <Box display="flex" alignItems="center" justifyContent="center"
                    px={4} flexDirection={"column"}
                >
                    <Box pb={1}>
                        {tipoEdoProspecto(seleccionado.StatusColor, seleccionado.StatusNom)}
                        <Card style={{ paddingRight: '1rem', paddingLeft: '1rem', width: 13.75 + 'rem' }}>
                            <Typography >    Seleccione una opción </Typography>
                        </Card>
                    </Box>
                    <Box>
                        <Button onClick={e => cambiarStatus(e, 3)} disabled={espera ? true : false}
                            variant="outlined" style={{ color: red[500], marginRight: 1 + 'rem' }}
                        >
                            Rechazar
                        </Button>
                        <span className={classes.wrapper}>
                            <Button onClick={e => cambiarStatus(e, 2)} style={{ color: green[500] }}
                                variant="outlined" disabled={espera ? true : false}
                            >
                                Validar
                            </Button>
                            {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </span>
                    </Box>
                </Box>
            : null}
        </Box>
    )

    const cardPerfil2 = (
        <Grid container className={classes.root}>
            <Grid item xs={sinImg ? 8 : 6}>
                {
                    seleccionado?.Imagen !== undefined && seleccionado.Imagen !== "" ?
                        <DialogContent className={classes.imgContent} >
                            <img src={seleccionado.Imagen} className={classes.imagen} alt="foto" />
                        </DialogContent>
                        :
                        <DialogContent>
                            <Typography className={classes.noDisponible}>
                                Sin foto disponible
                            </Typography>
                        </DialogContent>
                }
            </Grid>
            <Grid item xs={sinImg ? 4 : 6} style={{ alignContent: 'center', textAlign: 'center' }}>
                <Grid item xs={12}>
                    {seleccionado.Nombre ?
                        <DialogContent >
                            <Typography color="primary" variant="subtitle2" gutterBottom>
                                {fecha}
                            </Typography>

                            <Typography variant="subtitle2" style={{ fontWeight: 650 }}>
                                {seleccionado.Nombre}
                            </Typography>
                        </DialogContent>
                        : null
                    }
                </Grid>
                <Grid item xs={12}>
                    {seleccionado.StatusId === 1 ?
                        <>
                            <DialogContent  >
                                {tipoEdoProspecto(seleccionado.StatusColor, seleccionado.StatusNom)}
                                <Typography className={classes.margin}>
                                    Seleccione una opción
                                </Typography>
                                <DialogActions style={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center', padding: 0, marginTop: .5 + 'rem' }}>
                                    <Button onClick={e => cambiarStatus(e, 3)} disabled={espera ? true : false}
                                        variant="outlined" style={{ color: red[500], marginRight: 'auto', maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}
                                    >
                                        Rechazar
                                    </Button>
                                    <Button onClick={e => cambiarStatus(e, 2)}  variant="outlined" disabled={espera ? true : false}
                                        style={{ color: green[500], marginLeft: 'auto', maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}                                        
                                    >
                                        Validar
                                    </Button>
                                </DialogActions>
                                {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </DialogContent>
                        </>
                        : null}
                </Grid>
            </Grid>
        </Grid>
    )

    const cardPerfil3 = (
        <Grid container className={sinImg ? classes.root : classes.root2} justifyContent="center" >
            <Grid item xs={sinImg ? 7 : 6} >
            {   sinImg ?
                <DialogContent className={classes.imgContent}>
                    <img src={seleccionado.Imagen} className={classes.imagen} alt="foto" />
                </DialogContent>
                :
                <Card className={classes.noDisponible} >
                    <Typography> Sin foto disponible </Typography>
                </Card>
            }
            </Grid>
            <Grid item xs={sinImg ? 5 : 6} style={{ alignContent: 'center', textAlign: 'center' }}>
                <Card>
                    {   seleccionado.Nombre ?
                        <DialogContent className={classes.margin} style={{ padding: 0 }}>
                            <Typography variant="subtitle2" style={{ fontWeight: 650 }}>
                                {seleccionado.Nombre}
                            </Typography>
                            <Typography color="primary" variant="subtitle2" gutterBottom>
                                {fecha}
                            </Typography>
                        </DialogContent>
                        : null
                    }
                </Card>
                <Card style={{ marginTop: '1rem' }}>
                    {   seleccionado.StatusId === 1 ?
                        <>
                        <DialogContent >
                            {tipoEdoProspecto(seleccionado.StatusColor, seleccionado.StatusNom)}
                             <Typography className={classes.margin} variant="subtitle2"> Seleccione una opción </Typography>
                            {sinImg ?
                                <DialogActions style={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center', padding: 0, marginTop: '1rem', marginBottom: '1rem' }} >
                                    <Button onClick={e => cambiarStatus(e, 3)} disabled={btnDeshabilitado? true : espera  } //disabled={espera ? true : false}
                                        variant="outlined" style={{  marginRight: 'auto', maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}
                                        className={classes.buttonRechazar}
                                    >
                                        Rechazar
                                    </Button>
                                    <span className={classes.wrapper}>
                                    <Button onClick={e => cambiarStatus(e, 2)} variant="outlined" disabled={btnDeshabilitado? true : espera  }//disabled={espera ? true : false}
                                        style={{   marginLeft: 'auto', maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}                                        
                                        className={classes.buttonGuardar}
                                    >
                                        Validar
                                    </Button>
                                    {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </span>
                                </DialogActions>
                                :
                                <DialogActions style={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center', padding: 0, marginTop: '1rem', marginBottom: '1rem' }} >
                                     <span className={classes.wrapper}>
                                    <Button onClick={e => cambiarStatus(e, 3)} disabled={btnDeshabilitado? true : espera  }
                                        variant="outlined" color="error" style={{   maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}
                                        className={classes.buttonRechazar}
                                    >
                                        Rechazar
                                    </Button>
                                    {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </span>
                                </DialogActions>
                            }  
                            
                        </DialogContent>
                        </>
                        :
                        null
                    }
                </Card>
            </Grid>
        </Grid>
    )

    const auxEstilo = seleccionado?.Imagen !== undefined && seleccionado.Imagen !== "" ? { marginBottom: '1em' } : { marginBottom: '1em', minHeight: 4.3 + 'rem' }
    return (
        <ModalInformativo
            setModalAbierto={setModalAbierto} modalAbierto={modalAbierto}
            titulo={titulo} nvoTitulo={seleccionado} tamanio={seleccionado.StatusId === 1 ? '' : "sm"}
            //subtitulo={"Sección " + seleccionado.Secc}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} setAviso={setAviso}
        >
            {/* cardPerfil1(auxEstilo) */}
            {cardPerfil3}
        </ModalInformativo>
    )
}