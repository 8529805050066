import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {makeStyles} from "@material-ui/core/styles";
import {authUser} from "../funciones/AuthUser";
import {useHistory} from "react-router-dom";
import qs from "qs";
import axios from "axios";
import {useEffect, useState} from "react";
import {
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import moment from "moment";
import { celdaCard } from "../generales/TablaGeneral";
const useStyles = makeStyles({
    table: {
        maxHeight: "40vh",
        width: 60+'rem',
        overflowY: 'auto'
    },
});

const ApoyosSelected = ({apoyoSelected,fuente,apoyo, setApoyo,actualizar}) => {

    const classes = useStyles();
    let history = useHistory();
    const source = axios.CancelToken.source();
    let UsuId = localStorage.getItem('UsuId');
    let Dispvo = localStorage.getItem("Dispvo");
    let auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
    const [espera, setEspera] = useState(false);

    const apoyosDelPerfil = (auxUrl) => {
        let data = qs.stringify({
            usuario: UsuId, perfil: apoyoSelected.Id, idDispositivo: auxDispV,
        });
        let url = auxUrl;

        function respuesta(response) {
            if (response.length !== 0) {
                setApoyo(response)
            }
        };llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    useEffect(() => {
        if (!authUser()) {
            localStorage.clear()
            history.push("/");
        } else {
            let auxiliar=true
            setApoyo([])
            if (fuente===1) {
                apoyosDelPerfil("apoyo-perfil-det");    
                auxiliar=false
            }
            if (auxiliar&& fuente===2) {
                apoyosDelPerfil("apoyo-publico-perfil-det");                    
            } 
        }
    }, [apoyoSelected,actualizar]);

    const celdaEncabezado=(alinear,contenido)=>{
        return (
        <TableCell align={alinear} className={classes.tableCell} padding={'none'} 
        style={{backgroundColor: process.env.REACT_APP_Fondo_Color, color: "white"}} >
          {contenido}
        </TableCell>
        )
    }  
    const celda=(alinear,estilo,contenido)=>{
        return (
        <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
          {contenido}
        </TableCell>
        )
    } 

    return (
    <>
    <Grid item style={{marginTop: 15}}>
        <TableContainer component={Paper} className={classes.table}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {celdaEncabezado("center",`${apoyo.length} F. Alta`)}                        
                        {celdaEncabezado("center","Estatus")}
                        {celdaEncabezado("center","Cant.")}
                        {celdaEncabezado("center","Usuario")}                        
                        {celdaEncabezado("center","Tipo")}                        
                        {celdaEncabezado("center","Observación")}                                                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {apoyo.length === 0 ? <LinearProgress/> : apoyo.length !== 0 ?
                        apoyo.map((row) => {
                            let auxCol=row.StatusId===2 ? "#04cd1f": (row.StatusId===1 ? "#F46239":"red")
                        return(   
                            row.FAlta ?
                            <TableRow key={row.Descr}>
                                {celda("center",{},moment.utc(row.FAlta).format("DD/MM/YYYY"))}
                                {celdaCard(auxCol,row.StatusNom ,{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'},"center")}                                   
                                {celda("center",[],row.Cant)}
                                {celda("left",{fontSize:12+'px',paddingRight:.7+'rem' },row.UsrNom)}                                   
                                {celda("left",{fontSize:12+'px', },row.TipoNom)}                                   
                                {celda("left",[],row.Observ)}
                            </TableRow>
                            : <TableRow key={'Sin Datos'}>
                                <TableCell align="center">Sin</TableCell>
                                <TableCell align="center">Registros</TableCell>
                            </TableRow>
                        )})
                        : <TableRow key={'Sin Datos'}>
                            <TableCell align="center">Sin</TableCell>
                            <TableCell align="center">Registros</TableCell>
                        </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    </>)
        ;
};

export default ApoyosSelected;
