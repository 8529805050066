import React, {useEffect, useRef, useState} from "react";
import mapboxgl from 'mapbox-gl';
import { makeStyles } from '@material-ui/core/styles';
//import EdoGeoJson from "./data/15_EdoMex.geojson";
//const MapGam="https://daduva-cartografia.sfo3.cdn.digitaloceanspaces.com/REPMEX.geojson"
const useStyles = makeStyles({
    miclase:{".map-container":{
        height: "400px",
      }},
  });

function MapaGeneral({arregloColor,setMapRegis,long,lat,zoom, url,etiqueta}) {

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
    const classes = useStyles();
    const mapContainer = useRef(null);
    //const [long] = useState(-99.6140004);
    //const [lat] = useState(19.3670181);
    //const [zoom, setZoom] = useState(7.3);
    const [hoveredDistrict, _setHoveredDistrict] = useState(null);
    const hoveredDistrictRef = useRef(hoveredDistrict);

    let hoveredStateId = null;
    let stateName = null;

    const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
    });

    useEffect(() => {
        
        let nuevoArr=[
            'match',
            ['get', etiqueta],                         
        ]
        //let cuenta=0
        arregloColor.forEach((elemento)=>{
            nuevoArr.push(elemento.pos)
            nuevoArr.push(elemento.auxColor)
            //cuenta=cuenta+elemento.pos.length
        }) 
        nuevoArr.push(/* other  */'#cedded')
       // console.log(nuevoArr,cuenta);
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/light-v10",
            center: [long,lat ],
            zoom: zoom            
        });
        
        map.addControl(new mapboxgl.NavigationControl());

        map.on('load', () => {
            map.addSource('states', {
                type: 'geojson',
                data:url ,
            });

            map.addLayer({
                'id': 'state-fills',
                'type': 'fill',
                'source': 'states',
                'layout': {},
                'paint': {                    
                    'fill-color':nuevoArr,
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.3,
                        .75
                    ]
                }
            });

            map.addLayer({
                'id': 'state-line1',
                'type': 'line',
                'source': 'states',
                'layout': {},
                'paint': {                    
                    'line-color':'#0097A7',
                     
                }
            });
            map.on('click', 'state-fills', (e) => {
                
                e.preventDefault()
                setMapRegis(e.features[0].properties)                
                
                // {
                //   lngLat: {
                //      lng: 40.203,
                //      lat: -74.451
                //   },
                //   lngLats: [
                //      {
                //         lng: 40.203,
                //         lat: -74.451
                //      }
                //   ],
                //   originalEvent: {...},
                //   point: {
                //      x: 266,
                //      y: 464
                //   },
                //   points: [
                //      {
                //         x: 266,
                //         y: 464
                //      }
                //   ]
                //   preventDefault(),
                //   target: {...},
                //   type: "touchstart"
                // }
                });
            map.on('mousemove', 'state-fills', (e) => {
                if (e.features.length > 0) {
                    if (hoveredStateId !== null) {
                        map.setFeatureState(
                            {source: 'states', id: hoveredStateId},
                            {hover: false}
                        );
                    }
                    hoveredStateId = e.features[0].properties.CVEGEO;
                    map.setFeatureState(
                        {source: 'states', id: hoveredStateId},
                        {hover: true}
                    );
                    stateName = e.features[0].properties.NOMGEO;
                    _setHoveredDistrict(stateName)
                }
            });

            map.on('mouseleave', 'state-fills', () => {
                if (hoveredStateId !== null) {
                    map.setFeatureState(
                        {source: 'states', id: hoveredStateId},
                        {hover: false}
                    );
                }
                hoveredStateId = null;
            });


        });  
     
    }, [arregloColor,long]);


    return (
    <div className={classes.miclase} style={{width:480,height: "15rem"}} >
        <div className="info">
            Municipio  <strong>{hoveredDistrict ? hoveredDistrict : ""}</strong>
        </div>   
        {<div id="districtDetailMap" className={"map"} >
            <div style={{width:480,height: 450}} ref={mapContainer}>
            </div>
        </div>}
    </div>
    );
}

export default MapaGeneral;