import React, {useState, useEffect} from 'react';
import {FormControl,Grid,Box,MenuItem, Select,InputLabel} from '@material-ui/core'
import Select2 from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import Modal from '../generales/Modal';
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    formEncabezado:{
      marginTop:'1.7em',  
    },
    formAnotacion: {
      width:'28.5em'
    },
    formSelect: {
      width:'12em'
    },
    formSelectTipo: {
        marginTop:'1em',
        width:'24.5em'
    },
    formObs:{
        marginTop:'1.7em',
        width:'30em',
        height:'6em'
    },
  }));


export default function NuevaIncidencia({titulo,modalAbierto,setModalAbierto,listMuni,recargar}){
    const classes = useStyles(); 
    const history = useHistory();
    const source = axios.CancelToken.source(); 
    const auxId =localStorage.getItem('UsuId') ; 
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
    const [listaSeccion, setListSeccion] = useState([])
    const [lisSecComp, setLissSecComp] = useState([]);
    const [listaCasillas, setListaCasillas] = useState([])
    const [listaTipo, setListaTipo] = useState([])
    const [espera, setEspera] = useState(false)
    const [seccion,setSeccion] = useState(0);
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const[listSecc,setListSecc]=useState([]);
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [registro, setRegistro] = useState({  Tipo: null, Casilla: 0, Descr: "", Nota: ""})
    const {Tipo,Casilla,Descr,Nota} = registro
    const exPermitidaAlfaNumerica = /^([A-Za-z0-9áéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z0-9 _.,ñÑáéíóúÁÉÍÓÚ]*)$/
    const [munId,setMunId] = useState(null)
    useEffect(()=>{                
        getSecciones()
        getTipoInci()
        return ()=> {
            source.cancel();
        }  
    },[]);

    const getSecciones = () => {            
        const data = qs.stringify({                
            idDispositivo: auxDispV,
        });
        const url = "incidencia-web-list-secc";
        function respuesta(aux) {
            if (aux)
            {   const auxSecciones=aux;     
                let arreSecc=[], variable=0;
                auxSecciones.forEach((seccion) => {
                    if (variable!==seccion.SeccionId)
                    {
                        variable=seccion.SeccionId;                        
                        arreSecc.push({ value : variable, label :variable })                                               
                    }                                        
                })
                setLissSecComp(aux);
                setListSeccion(arreSecc);
            }       
        }                        
        llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)                     
    }; 

     const getTipoInci = () => {            
        const data = qs.stringify({                
            idDispositivo: auxDispV,
        });
        const url =  "incidencia-tipo";//"incidencia-movil-tipo";
        function respuesta(aux) {
            if (aux)
            {
                let auxTipos=aux;                        
                let auxArreTipos=[];                                            
                auxTipos.forEach((tipo) =>
                {                                                        
                    if (tipo.AreaId===1||tipo.AreaId===2) {                                                                                                                        
                        auxArreTipos.push({ Id: tipo.Id, label : tipo.AreaNom+" - "+tipo.Nombre })                                                                                                       
                    }    
                })
                setListaTipo(auxArreTipos);                             
            }      
        }                        
        llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)                     
    }; 

    
    function onChangeTipo(e){
        setRegistro({ ...registro, Tipo: e.target.value })
    }

    function onChangeSeccion(e){
        const dato= lisSecComp.filter(  (registro) => registro.SeccionId === e.value )                          
        let arreCasilla=[];                      
        dato.forEach((seccion) => 
        {            
            arreCasilla.push({ value : seccion.CasillaId, label : seccion.TipoNom })      
            //console.log(seccion.CasillaId+" "+seccion.TipoNom);                                                                                             
        })
        setSeccion(e.value)
        setListaCasillas(arreCasilla);
    }

    function onChangeCasilla(e){
        setRegistro({ ...registro, Casilla: e.value })
    }

    function onChangeDescripcion(e){
        if(exPermitidaAlfaNumerica.test(e.target.value)||e.target.value===' '||e.target.value==='')
        {   setRegistro({ ...registro, Descr: e.target.value })
        }
    }
    function onChangeNota(e){
        if(exPermitidaAlfaNumerica.test(e.target.value)||e.target.value===' '||e.target.value==='')
        {   setRegistro({ ...registro, Nota: e.target.value })
        }
    }

    function verificarTipo(tp){
        let validado=false;
        if(parseInt(tp) !== 0 )
        {   validado=true
        }
        else
        {   setTipoAdvertencia("warning")
            setMensaje("Seleccione un tipo válido")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    
    const errorVal=(auxMensaje)=>{
        setTipoAdvertencia("warning")
        setMensaje(auxMensaje)
        setAviso(true)
    }
 
    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;                  
        if( campo===null || (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                                               
            errorVal('No se lleno el campo '+descripcion)
        }
        return(respuesta);
    }     

    function verificarCasilla(cas){
        let validado=false;
        if(parseInt(cas) !== 0)
        {   validado=true
        }
        else
        {   setTipoAdvertencia("warning")
            setMensaje("Seleccione una casilla válida")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function verificarDescripcion(des){
        let validado=false;
        if(des && des!==" ")
        {   validado=true
        }
        else
        {   setTipoAdvertencia("warning")
            setMensaje("Ingrese Descripción")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function handleSubmition()
    {
        /* if( verificarTipo(Tipo) && verificarCasilla(Casilla) && verificarDescripcion(Descr))
        {   registrarIncidencia()
        } */

        if( !verificaNulos(munId,"Municipio") &&!verificaNulos(seccion,"Sección") 
        && !verificaNulos(Tipo,"Tipo") && verificarDescripcion(Descr))
        {   registrarIncidencia()
        }
    }

 /*    const registrarIncidencia = () => {  
        setBtnDeshabilitado(true);              
        const data = qs.stringify({
            Tipo:Tipo,   
            Usuario:auxId,
            Casilla:Tipo,
            Descripcion: Descr,
            Nota:Nota,
            Otracausa:(Tipo===5 || Tipo===7 ?(Descr) :(" ")),
            idDispositivo: auxDispV,
        });
        const url = "incidencia-web-nuevo";
        function respuesta(aux) {
            if(aux.respuesta===1)
            {   setTipoAdvertencia("success")
                setMensaje("Incidencia registrada con éxito")
                toast.success("Incidencia registrada con éxito", 
                    {  position: toast.POSITION.BOTTOM_RIGHT,  theme: "colored", autoClose: 3500, 
                    containerId:'ModalNvaIncidencia'} );   
                setAviso(true)
                setSeccion(0)
                setRegistro({...registro, Casilla:0, Descr:"", Nota:"", Tipo:0})
            }  
            else
            {   setBtnDeshabilitado(false)
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    }; */

    const registrarIncidencia = () => {  
        setBtnDeshabilitado(true);           
        const actual=new Date()
        const hoy = moment(actual ).format("YYYY-MM-DDTHH:mm")   
        const hora = moment( actual).format("HH:mm")   
        const data = qs.stringify({
            Tipo:Tipo,   
            Muni:munId,
            Secc:seccion,
            Usuario:auxId,
            Fcaptura:`${hoy}:00`,
            Casilla:0,//Tipo,
            Latitud:0.0,
            Longitud:0.0,
            Horario:hora,//"16:32",
            Descripcion: Descr,
            Nota:Nota,
            Otracausa:"SN",//(Tipo===5 || Tipo===7 ?(Descr) :(" ")),
            idDispositivo: auxDispV,
        });
        const url = "incidencia-nuevo";
        function respuesta(aux) {
            if(aux.respuesta===1)
            {   setTipoAdvertencia("success")
               // setMensaje("Incidencia registrada con éxito")
                toast.success("Incidencia registrada con éxito", 
                    {   pauseOnFocusLoss: false,     
                        toastId:'ModalNvaIncidencia1'} );   
                setAviso(true)
                setSeccion(0)
                setRegistro({...registro, Casilla:0, Descr:"", Nota:"", Tipo:0})
                recargar()
                setModalAbierto(false)
            }  
            else
            {   setBtnDeshabilitado(false)
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };     

    function llamadaListSecc(auxMuni)  {            
        const url= `lugar-seccion`;                            
        const data = qs.stringify({                
            usuario:auxId,              
            municipio:auxMuni,                
            idDispositivo:auxDispV,
        });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListSecc(aux)
            }                                                            
        }
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    };

    const onChangeMun=(e)=>{           
        if (e!== null && e!== undefined) 
        {   
            llamadaListSecc(e.target.value)
            setMunId( e.target.value )                            
        }
        else
        {   setMunId(null)           
        }   
        setSeccion(null)         
    }  
    const onChangeSecc=(e)=>{    
        if (e!== null && e!== undefined) 
        {   setSeccion( e.target.value )                            
        }
        else
        {   setSeccion(null)           
        }        
    }  

    
    const listaSec= listSecc.length!==0 ?                    
    <FormControl size='small'  style={{ minWidth:8.5+'rem',marginLeft:".5rem",marginTop:".3rem"}} >
        <InputLabel size='small' variant="outlined" id="muni-label">
            Sección
        </InputLabel>
        <Select  size='small' variant="outlined" 
            label="Sección" id="secc-select2" name="seccId"
            value={seccion} onChange={onChangeSecc}
        >
            {listSecc.map(( secc ) => {                        
                return(
                <MenuItem size='small' value={secc.Id} key={secc.Id}>
                    <em>{secc.Numero }</em>
                </MenuItem>
                )
            } )}                    
        </Select>
    </FormControl>    
    :   null

    const listaMun= listMuni.length!==0 ?                    
    <FormControl size='small'  style={{ minWidth:8.5+'rem',marginTop:".3rem"}} >
        <InputLabel size='small' variant="outlined" id="muni-label">
            Municipio
        </InputLabel>
        <Select  size='small' variant="outlined" 
            label="Municipio" id="muni-select2" name="munId"
            value={munId} onChange={onChangeMun}
        >
            {listMuni.map(( edo ) => {                        
                return(
                <MenuItem size='small' value={edo.Id} key={edo.Id}>
                    <em>{edo.Nom }</em>
                </MenuItem>
                )
            } )}                    
        </Select>
    </FormControl>    
    :   null


    return (
    <Modal titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={handleSubmition}
        mensaje={mensaje}  tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}
    >
        <form className={classes.root} noValidate autoComplete="off"> 
            <Grid container spacing={1}>
                {/* listaSeccion.length !== 0 ? 
                <Grid item sx={6}>
                    <FormControl className={classes.formSelect}>
                        <Select placeholder={"Sección"}  options={listaSeccion}
                            onChange={opt =>  onChangeSeccion(opt) }
                            styles={
                                {   menuList: styles => ({ ...styles,height:'250px' }),
                                }
                            }
                            noOptionsMessage={ () =>  ('Sin Resultados') }/>   
                    </FormControl>
                </Grid> 
                :null
                }
                {seccion>0 && listaCasillas.length!==0 ? 
                <Grid item sx={1}>
                    <FormControl className={classes.formSelect}>
                        <Select placeholder={"Casilla"}  options={listaCasillas}
                            onChange={opt =>  onChangeCasilla(opt) }
                            styles={
                                {   menuList: styles => ({ ...styles,height:'100px' }),
                                }
                            }
                            noOptionsMessage={ () =>  ('Sin Resultados') }/>   
                    </FormControl>
                </Grid> 
                :null */
                }
                {listaMun}
                {listaSec}
            </Grid>
            <Grid container spacing={1} style={{marginTop:".5rem"}}>
                {listaTipo.length!==0 ? 
                <Grid item sx={6}>
                    <FormControl size='small'  style={{ minWidth:8.5+'rem',marginTop:".3rem"}} >
                        <InputLabel size='small' variant="outlined" id="Tipo-label">
                            Tipo
                        </InputLabel>
                        <Select  size='small' variant="outlined" 
                            label="Tipo" id="Tipo-select2" name="Tipo"
                            value={Tipo} onChange={onChangeTipo}
                        >
                            {listaTipo.map(( auxTipo ,index) => {                        
                                return(
                                <MenuItem size='small' value={auxTipo.Id} key={index}>
                                    <em>{auxTipo.label }</em>
                                </MenuItem>
                                )
                            } )}                    
                        </Select>
                    </FormControl>    
                </Grid> :null
                }
            </Grid>
            <Box display="flex" flexDirection="column">
                <textarea className={classes.formObs} placeholder="Descripción"
                    value={Descr} onChange={onChangeDescripcion} id={'Descr'}
                    multiline rows={2} maxRows={4}/>
                <textarea className={classes.formObs} placeholder="Nota"
                    value={Nota} onChange={onChangeNota} id={'Nota'}
                    multiline rows={2} maxRows={4}/>
            </Box>
        </form>
    </Modal>
    )
}