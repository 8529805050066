import React, { useEffect, useState } from "react";
import Mapa from "./Mapa";
import {  Box, Paper, Card ,IconButton,Tooltip} from "@material-ui/core";
import { generaCoordenadaDinam } from "../funciones/Mapas/FormatoCoord";
import { obtenColor } from "../generales/Objetos";
import InfoIcon from '@material-ui/icons/Info';
 
const MapaGPS = ({ muniGps, zoom, tipo,coordPerfiles,auxMapVar}) => {
  const libreriaG = [process.env.REACT_APP_GOOGLE_LIBRARIE];
  let altuTamMax = 600;
  const [listSeccion, setListSeccion] = useState([]);
  const [listCentro, setListCentro] = useState([]);
  const [modalAbierto,setModalAbierto] = useState(false)

  useEffect(() => {
    let auxSecc = [];
    let centro=[], contador=0
      //console.log(muniGps);
    muniGps.forEach((seccion) => {
      if (seccion.gps) 
      {                    
        let aux = [];      
        seccion.gps.forEach((element) => {
          let pathSecc = generaCoordenadaDinam(element.lat, element.lon);
          aux.push(pathSecc);
        });
        //console.log(aux,seccion.Id);
        auxSecc.push({
          coordenada: aux,
          color: obtenColor((seccion.Avance / seccion.Meta) * 100),
          nombre:seccion.Nom,
          Id:seccion.Id,
          avance:Math.round((seccion.Avance / seccion.Meta) * 100),
          avanceNum:seccion.Avance,
          meta:seccion.Meta
        });
        //console.log(aux,seccion.Id,auxSecc[contador].coordenada);
        let snOrd=ordenarCoord(auxSecc[contador].coordenada)
        centro.push({coordenada:generaCoordenadaDinam(snOrd.coordX, snOrd.coordY),nombre:"Sección "+seccion.Nom})      
        contador++
      }

    });
 
    setListCentro(centro)
    setListSeccion(auxSecc);
 

  }, [muniGps]);

  const ordCorto=(arreglo)=>{
    let respuesta=arreglo
    respuesta.sort( (el1, el2) => {
      if(el1> el2 ) // again- I used > sign, put the actual comparison logic here, i.g: el1.coordinateid[0] > el2.coordinateid[0] and so on
        return -1;
      else return 1;
    });
    return respuesta
  }
  const ordenarCoord=(coord)=>{
    let aux1=[] , aux2=[] 
    coord.forEach((elemento,index)=>{
      aux1[index]=elemento.lat
      aux2[index]=elemento.lng      
    })    
    let ord1=ordCorto(aux1)
    let ord2=ordCorto(aux2)
    let coordX= ord1[ord1.length-1] + (ord1[0]-ord1[ord1.length-1])/2
    let coordY= ord2[ord2.length-1] + (ord2[0]-ord2[ord1.length-1])/2
    //console.log(coordX,coordY);
    return {coordX,coordY}
  }


  const abrirModal=()=>{
    return <Box style={{ position: "absolute", width: 2 + "rem" }}>
    <Paper elevation={3}>
      <Card>
      <Tooltip title={"Mostrar más"}> 
        <IconButton   aria-label="agregar imagen" size="small" onClick={(e) => setModalAbierto(true)} 
          component="span">
          <InfoIcon  color="primary" /> 
        </IconButton>
      </Tooltip>
      </Card>
    </Paper>
  </Box>
  }
  const tamanioH =
    window.innerHeight < altuTamMax
      ? window.innerHeight - 150
      : window.innerHeight - 250;

  return (
    <div>
      <Box width={35 + "rem"} ml={2}>
    
        {listSeccion.length !== 0 && !modalAbierto? (
          <Mapa
            zoom={zoom} auxMapVar={auxMapVar}
            tamanio={{ height: tamanioH, width: "100%" }}
            libreriaG={libreriaG} abrirModal={abrirModal}
            listSeccion={listSeccion}  coordPerfiles={coordPerfiles}     
            listCentro={listCentro}    tipo={tipo}   
          />
        ) : null}
      </Box>
    </div>
  );
};

export default MapaGPS;
