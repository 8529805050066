import {IconButton, Tooltip} from "@material-ui/core";
import UpdateIcon from '@material-ui/icons/Update';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import {useEffect, useState} from "react";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
const SelectStatus = ({element, apoyo, setApoyo, setBotonDeshabilitado}) => {

    const [color, setColor] = useState(apoyo.StatusId);

    useEffect(() => {
        setColor(apoyo.StatusId)
    }, [apoyo.StatusId]);


    const handleStatus = (event,valor) => {
        event.preventDefault();
        setBotonDeshabilitado(false)
        if (valor === 1) {
            return setApoyo({
                ...apoyo, StatusId: 1,
            });
        } else
         {  if (valor === 2) {
            return setApoyo({
                ...apoyo, StatusId: 2,
            });
            } else
            return setApoyo({
                ...apoyo, StatusId: 0,
            });
        }
    };

    return (<>
        {apoyo.StatusId === 1 || apoyo.StatusId === 2 ? 
        <>  <Tooltip  title={"Sin asignar"} >
                <IconButton onClick={(e)=>handleStatus(e,0)}  size="small" >
                    <HighlightOffIcon style={apoyo.StatusId === 0 ? {color: 'red'} : null} size="small" />
                </IconButton>
            </Tooltip>
            <Tooltip  title={"Pendiente"} >
                <IconButton onClick={(e)=>handleStatus(e,1)} size="small"  >
                    <UpdateIcon style={color === 1 ? {color: '#F46239'} : null} size="small" />
                </IconButton>
            </Tooltip>
            <Tooltip  title={"Entregado"} >
                <IconButton onClick={(e)=>handleStatus(e,2)}  size="small" >
                    <CheckCircleOutlineOutlinedIcon style={color === 2 ? {color: '#04cd1f'} : null} size="small" />
                </IconButton>
            </Tooltip>
        </> 
        :   <>
            <Tooltip  title={"Sin asignar"} >
                <IconButton onClick={(e)=>handleStatus(e,0)}  size="small" >
                    <HighlightOffIcon style={apoyo.StatusId === 0 ? {color: 'red'} : null} size="small" />
                </IconButton>
            </Tooltip>
            <Tooltip  title={"Pendiente"} >
                <IconButton onClick={(e)=>handleStatus(e,1)} size="small" >
                    <UpdateIcon size="small" />
                </IconButton>
            </Tooltip>
            <Tooltip  title={"Entregado"} >
                <IconButton onClick={(e)=>handleStatus(e,2)} size="small" >
                    <CheckCircleOutlineOutlinedIcon   size="small" />
                </IconButton>
            </Tooltip>
            </>
        }
    </>
    );
};

export default SelectStatus;