import React,{useState,useEffect} from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import {Box, LinearProgress, FormControl,MenuItem,
   Typography, InputLabel,Select} from "@material-ui/core";
//import Reporte from './Reporte'
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {llamaApiCargaToken, llamadaApiToken, ErrorGeneral } from '../funciones/ClienteAxios';
import { obtenDispo } from '../funciones/IdDispositivo';
import SelectList from '../generales/SelectList';
import TablaAgrupamiento from './TablaAgrupa';
import TablaPromovidos from './TablaPromov';
const PromovXUsr = () => {
  let history = useHistory();    
  const colFondo=process.env.REACT_APP_Fondo_Color
  const source = axios.CancelToken.source();  
  const usu= localStorage.getItem('UsuarioNom');
  const nvl= localStorage.getItem('Tipo');
  const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0;
  const auxDispV =obtenDispo()
  const {usuario,guardarUsuario}=React.useContext(AuthContext);  
  const {generales,guardarGenerales}= React.useContext(GeneralesContext);    
  const [datos,setDatos]= useState([])
  const [espera,setEspera]= useState(false)
  const [esperaList,setEsperaList]= useState(false)
  const [esperaMuni,setEsperaMuni]= useState(false)
  const [esperaSecc,setEsperaSecc]= useState(false)
  const [listUsu, setListUsu] = useState([]);
  const[listSecc, setListSecc] = useState([])    
  const[listProm, setListProm] = useState([])
 
  const [etiqMuni, setEtiqMuni] = useState("");
  const [filtros,setFiltros]= useState({edo:15, usr:'', Seccion:'', manzana:''})
  const{edo, usr, Seccion, manzana}=filtros
  
  useEffect(()=>{
    
    const veriSesion =  () => 
    { if(usu==null)
      {                                
        guardarUsuario([])						              
        localStorage.clear();           
        history.push("/")	
      }
      else
      { if (usuario.length===0) 
        { guardarUsuario({Nombre:usu,nivel:nvl});                                      
        }
        getListUsu() 
        guardarGenerales({...generales,Titulo:'Exportación de Promovidos'})   
      }
    //  getListTotal()
    }
    veriSesion()    
    
    return ()=> {
      source.cancel();
    }
  },[])
 

  useEffect(() => {
    if (usr) 
    {
      getListSecc(usr)    
      const filtro=listUsu.filter((munici)=>munici.id===usr)
      //console.log(filtro);
      //let filtrado = listOrig.filter((auxApoyo) => auxApoyo.TipoId === element.Id)
      setEtiqMuni(filtro[0].nom)             
    }
        
  }, [usr])
  
  useEffect(() => {
    if (Seccion) 
    {
      //getListManzana(Seccion)        
      getListProm(Seccion!=="-1" ?Seccion: "0")         
    }
        
  }, [Seccion])
  

  function getListUsu()  {            
    const url= `perfil/v1/captura/usuario`;                            
    const data = qs.stringify({                
      usuario:usuID,  idDispositivo:auxDispV,
    });
                
    function respuesta (aux) 
    { if (aux[0].id != -1) 
      { setListUsu(aux)
      }  
      else{
        setListUsu([])
      }                                                                    
    }
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEsperaMuni, history, source);
  };

  const getListSecc = (auxUsu) => {
    const data = qs.stringify({
      usuario: auxUsu, idDispositivo: auxDispV,
    });
    const url = "perfil/v1/captura/usuario/seccion";

    function respuestaSecc(auxiliar) {
      if (auxiliar[0].id != -1) {                
        setListSecc(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaSecc, history, source);
  }

  const getListProm = (auxSecc) => {
    const data = qs.stringify({
      usuario:usr, seccion:auxSecc, idDispositivo: auxDispV,
    });
    const url = "perfil/v1/captura/usuario/seccion/export";    
    function respuestaSecc(auxiliar) {
      if (auxiliar.secciones) {                
        setListProm(auxiliar)                
     
      }
    }

    llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEsperaList, history, source);
  }

  const filtroSlct=(e)=>{
    let arr=[]
    //console.log(e);
    switch (e.target.name) {             
      case 'edo':
        arr={...filtros, edo:e.target.value,
          usr:"", Seccion:'', manzana:''}
        setListSecc([])
        
        break
      case 'usr':
        arr={...filtros, usr:e.target.value, Seccion:'', manzana:''}
      
        break
      case 'Seccion':
        arr={...filtros, Seccion:e.target.value }
        break
      default:
        arr= {...filtros, [e.target.name]:e.target.value}
        break;
    }  
    setFiltros(arr)
  }

  const onChangeSlct=(e)=>{      
    e.preventDefault()      
    if (e!== null && e!== undefined) 
    {   filtroSlct(e) 
    }
    else
    {   setFiltros({...filtros,[e.target.name]:""})          
    }   
    setListProm([])     
  }

  const slctUsu =                        
    <SelectList lista={listUsu} value={usr} onChange={onChangeSlct} 
      etqVal={"id"} etqId="id" etqAlias={"nom"} etiq={"Usuario"} nameEtq={"usr"}
      estilo={{minWidth:22+'rem',marginLeft:".5rem"}} 
    /> 

  const slctSecc =                        
    <SelectList lista={listSecc} value={Seccion} onChange={onChangeSlct} 
      etqVal={"id"} etqId="id" etqAlias={"nom"} etiq={"Sección"} nameEtq={"Seccion"}
      estilo={{minWidth:'8.5rem',marginLeft:".5rem"}} 
    />  

  const slctSecc2= <Box display={"flex"} >
    <FormControl size='small'  style={{marginLeft:1+'rem',marginTop:.4+'rem', width:8.5+'rem'}} >
      <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
      <Select  size='small' variant="outlined"
        label="Sección" id="seccc-select2" name="Seccion"
        value={Seccion} onChange={onChangeSlct}
      >
        {listSecc.map((secc, index) => {
          return(          
          <MenuItem size='small' value={secc.id===0 ?"-1":secc.id} key={secc.id+1}>
            <em>{secc.id===0?"TODOS": secc.nom }</em>
          </MenuItem>                         
          )
        } )}                    
      </Select>
      </FormControl>
    </Box>

  return (           
  <div style={{height:window.innerHeight-110 }} id="cajaSudo">
    <Box >
  {/*     <Typography style={{marginBottom: ".5rem",marginTop: "1rem"}}>  Busqueda por Secciones </Typography>     */}              
      <Box display={"flex"}>
        {esperaMuni ? <Box width={"15rem"} mt={3}><LinearProgress/></Box> : (listUsu.length!==0 ? slctUsu :null)}                      
        {esperaSecc ? <Box width={"15rem"} mt={3} ml={2}><LinearProgress/></Box> : (listSecc.length!==0 ?  slctSecc2:null)}
      </Box>
    </Box> 
    {esperaList?
      <Box width={"20rem"} mt={3}><LinearProgress/></Box>
    :
      (listProm.length!==0 ?
        <Box display={"flex"}>
       
    
          <Box>
            <TablaPromovidos listAv={listProm} />
          </Box>
    
          <TablaAgrupamiento listAv={listProm} /> 
        </Box>
      :null)      
    }
 
  </div>                           
  )
}

export default PromovXUsr