import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import {Card, Grid, Paper, Typography, Box} from "@material-ui/core";
import Viewer from 'react-viewer';
import moment from 'moment';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinkIcon from '@material-ui/icons/Link';

const useStyles = makeStyles((theme) => ({
    imageSize: {
        width: '100%', height: '20vh'
    }, 
    container: {
        overflowY: 'auto', maxHeight: window.innerHeight - 210, marginTop: '.2rem'
    }, 
    content: {
        padding: '0!important', margin: 0
    },
    cajaDetalle:{
        overflowY: 'auto', maxHeight: window.innerHeight - 370,  
    },
    cajaGaleria:{
        overflowY: 'auto', maxHeight: window.innerHeight - 550,  
    }
}));

const Galeria = ({galeria,listDetalle,regis}) => {    
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [visible, setVisible] = useState(false);
    const info="#2979ff", success="#06DE72"
    const handleViewer = (index) => {
        setVisible(true)
        setIndex(index)
    }

    const lista = () => {
        return galeria.map((element, index) => {
          //  console.log(galeria)
            return <Grid item xs={4}>
                <img
                    src={element.Dir + element.Mini}
                    className={classes.imageSize}
                    alt={element.Descr}
                    id={index}
                    key={index}
                    name={element.Descr}
                    onClick={() => handleViewer(index)}
                />
            </Grid>
        })
    }

    const viewer = <Paper square>
        <Viewer
            visible={visible}
            onClose={() => {
                setVisible(false);
            }}
            images={galeria.map((element) => {
                return ({src: element.Dir + element.Nom, alt: element.Descr})
            })}
            activeIndex={index}
            zIndex={2000}
        />
    </Paper>

    const fechValida =(fecha)=>{
        let nvoFech=""
        if(fecha!=="1900-01-01T00:00:00.000Z")
        {
            nvoFech=moment.utc(fecha).format("hh:mm a DD/MM/YYYY ")
        }
        return nvoFech!=="" ?<Typography variant="body1"  style={{fontSize:"12px", fontWeight:700}}>{nvoFech}</Typography> : null
    }

    return (<>
    <Grid container spacing={1} className={classes.container}>
        <Grid container xs={12} style={{marginLeft:"1rem"}}>
            <Typography variant="body1" style={{fontSize:"13px", fontWeight:700}} color="secondary">
                {regis.Titulo} 
            </Typography>
        </Grid>
        <Grid container xs={12} style={{marginLeft:"1rem"}}>
            <Typography variant="body1" style={{fontSize:"12px"}}>
                {regis.Msg} 
            </Typography>
        </Grid>
        {regis.Link!==""?
            <Grid container xs={12} style={{marginLeft:".5rem"}}>
                <LinkIcon color="secondary"/>
                <a style={{marginLeft:".3rem"}} href={regis.Link}>
                    {regis.Link} 
                </a>
            </Grid>
        :null} 
        
        <Grid container xs={12}  className={classes.cajaGaleria}>
            {galeria.length !==0?lista():null}
        </Grid>
        
        {viewer}
        <Grid item container    className={classes.cajaDetalle}  >            
            {listDetalle.map((detalle,index)=>
            <Grid item   xs={12} style={{marginBottom:".3rem"}} >
                <Card>
                    <Box ml={1}>
                        <Box display={"flex"} style={{padding:0,margin:0}}>
                            <Box mr={1}>
                                {detalle.SRead===1 ? 
                                <CheckCircleIcon fontSize="small" style={{color:detalle.Status===0 ?info:success }} />
                                : <CheckCircleOutlineIcon fontSize="small" />}
                            </Box>         
                            <Typography variant="body1" color="secondary">
                                { `${detalle.UsrNom}`}
                            </Typography>
                        </Box>
                                                
                        {fechValida(detalle.FValida)}                                  
                        <Typography variant="body1">
                            {`${detalle.Obsrv!==""?detalle.Obsrv:"Sin Comentarios"}`}
                        </Typography>
                    </Box>
                </Card>
            </Grid>
            )}
        </Grid>
    </Grid>
    </>);
};

export default Galeria;