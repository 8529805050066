import React,{useState,useContext} from 'react'
import {Box,IconButton,    Table,TableBody,TableCell,LinearProgress,      
    TableContainer,TableHead,TableRow,Card,Tooltip,TablePagination} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import {AuthContext} from '../context/AuthContext'
import ModalEditPerfil from '../EditarPerfil/ModalEditPerfil';
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import ModalMapa from './ModalMapa'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InfoPromo from './ModInfo';
import InfoIcon from '@material-ui/icons/Info';
import ImageIcon from '@material-ui/icons/Image';
import ModalNvoPerfilImg from '../nvoPerfil/ModalNvoPerfImg';
import { generaCoordenadaDinam } from "../funciones/Mapas/FormatoCoord";
import { llamadaApi,ErrorGeneral,llamadaApiCarga,llamadaApiToken } from '../funciones/ClienteAxios';
import SaveIcon from '@material-ui/icons/Save';
import CreateIcon from '@material-ui/icons/Create';
import {exportarArch} from '../funciones/ListaExcel'
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";
const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-252,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:55+'rem',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
  /*{
      id:2, titulo:"Reg",alinear:'center'
    }, */     
    {
      id:3, titulo:"Registro", alinear:'center'
    }, 
    {
      id:7, titulo:"Sección", alinear:'center'
    },
    {
      id:6, titulo:"Teléfono", alinear:'center'
    }, 
    {
      id:10,titulo:"Usuario", alinear:'center'
    }, 
    {
      id:11, titulo:"Nombre",alinear:'center'
    },
    {
      id:9, titulo:"Email", alinear:'center'
    }, 
    {
      id:5, titulo:"Verificación", alinear:'center'
    },     
    {
      id:4, titulo:"Nacimiento", alinear:'center'
    },    
    
  ];

const TablaXMuni = ({fSelect,actualizar,setActualizar,setArregloColor,muniSelect,setTotalPerfil,setInfoNvlCoord,setMuniGps,setCoordPerfiles,setAuxMapVar,listMun}) => {
  const classes = useStyles();
  const tamMax=150
  const fondo=process.env.REACT_APP_Fondo_Color
  const colSecond=process.env.REACT_APP_Color_Second
  let history = useHistory();         
  const usu= localStorage.getItem('UsuarioNom');    
  const nvl= localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;  
  const usuID = localStorage.getItem('UsuId');
  let Dispvo =localStorage.getItem('Dispvo') ;    
  let auxDispV =Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'  
  const source = axios.CancelToken.source();
  const [datos, guardarDatos] =  useState([])  
  const[sinDatos,guardarSinDatos]=  useState(true)
  const [espera,setEspera]= useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [regisSelect, setRegisSelect] = useState([]);  
  const {usuario,guardarUsuario}= useContext(AuthContext);
  const {generales,guardarGenerales}= useContext(GeneralesContext); 
  const [infoMapaMod, setInfoMapaMod] = useState(false);
  const [subtInfo, setSubtInfo] = useState("")    
  const [tituloMapa,setTituloMapa] = useState("")
  const [modalInfo, setModalInfo] = useState(false);
  const [nvoRegisPerfil, setNvoRegisPerfil] = useState([]);
  const [modalNvoImg, setModalNvoImg] =  useState(false);
  const [listPlantilla,setListPlantilla]= useState([])
  const [listaExcel, setListaExcel] =  useState([]);
  const [listSecc,setListSecc]= useState([])
  
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,setListPagina]=useState([]);
  const [modalEditPerfil, setModalEditPerfil] = useState(false);
  
  React.useEffect(()=>
  { //console.log(source.token);     
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");
    setAuxMapVar([])   
    setSelectedID(null)   
    setRegisSelect([])
    const llamadaPerfilLista=()=>{
      let data = qs.stringify({
        usuario:usuID,       
        fini:fInicio+'T00:00:00',
        ffin:fFinal+'T23:59:59',
        status:0,          
        municipio:muniSelect.Id,                                         
        idDispositivo: auxDispV,
    });
      let url = "perfil-lista-municipio";
      function respuestaPerfilList(aux) {
        if (aux[0].Id != -1) 
        {  //console.log(aux);  
          guardarSinDatos(true) 
          let auxPendiente=0
          let auxVerificado=0
          let auxRechazado=0
          let auxArrMuni=[],auxArrCoordP=[],arrM=[]
          arrM.push(["#","Municipio","Sección","Nombre","Teléfono","Email",
            "Fecha Registro","Fecha Nacimiento","Foto"])
          aux.forEach((perfil,index) => {
            if (perfil.StatusId===1) 
            { auxPendiente=auxPendiente+1
            }
            if (perfil.StatusId===2) 
            { auxVerificado=auxVerificado+1
            }
            if (perfil.StatusId===3) 
            { auxRechazado=auxRechazado+1
            }
            let idColor= ordenIdColor(muniSelect.Id)
            auxArrMuni.push({Id:idColor,auxColor:colSecond,IdOriginal:muniSelect.Id})


           let aux1= generaCoordenadaDinam(perfil.Lat, perfil.Lon)
           auxArrCoordP.push({coordenada:aux1,perfil:perfil.Nombre+"\n"+perfil.Tel})
           //let auxMuni=perfil.MunNom.charAt(0)+ perfil.MunNom.slice(1).toLowerCase()   
           let auxFReg=moment.utc( perfil.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( perfil.Fecha).format('DD/MM/YYYY HH:mm')
           let auxNac=moment.utc( perfil.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
           ? "" : moment.utc( perfil.Nacimiento).format('DD/MM/YYYY') 
           arrM.push([index+1,muniSelect.Nom,perfil.SeccNum,perfil.Nombre,perfil.Tel,perfil.Email,auxFReg,auxNac,perfil.Imagen])
          });
          //auxTotal.push()
          setListaExcel(arrM)
          setTotalPerfil({
            Valido:auxVerificado,
            Pendiente:auxPendiente,
            Rechazado:auxRechazado})   
          numeroPag(aux,tamMax,setTotalPaginas,setNumPagina,setListPagina)                                                       
          guardarDatos(aux)
          setCoordPerfiles(auxArrCoordP)
          //console.log(aux);
          setArregloColor(auxArrMuni)
        }
        else
        { guardarDatos([])
          setListPagina([])
          setTotalPerfil([])
          guardarSinDatos(false)     
          setArregloColor([])  
          setListaExcel([])
        }
      }
      llamadaApiCarga(data, url, respuestaPerfilList,ErrorGeneral,setEspera,history);
    }
  
    const llamadaPlantilla=()=>{
      let data = qs.stringify({
        usuario:usuID,          
        dominio:process.env.REACT_APP_Dominio,                                      
        idDispositivo: auxDispV,
    });
      let url = "plantilla-base-dominio";
      function respuestaPlantilla(auxiliar) {
        if (auxiliar[0].Id != -1) 
        { setListPlantilla(auxiliar)           
        }
      }
      llamadaApi(data, url, respuestaPlantilla,ErrorGeneral,history);
    }

    const llenaPerfilEstrc=()=>{
      let data = qs.stringify({
        usuario:usuID,                                        
        tipo:5,
        zon:0,
        reg:0,
        mun:muniSelect.Id,
        sec:0,
        pro:0,
        idDispositivo: auxDispV,
      });
      function respuestaPerfilEst(auxiliar){
        if (auxiliar[0].Id != -1) 
        { //console.log(auxiliar);  
          setInfoNvlCoord(auxiliar)             
        }                               
      }
      let url = "perfil-estructura-perfil";
      llamadaApiToken(data, url, respuestaPerfilEst,ErrorGeneral,history,source);
    }      

    const llamarListSecc=()=>{
      let data = qs.stringify({
        usuario:usuID,                                            
        idDispositivo: auxDispV,
      });
      function respuestaPerfilEst(aux){
        if (aux[0].Id != -1) 
        { //console.log(auxiliar);  
          setListSecc(aux)        
        }                               
      }
      let url = "lugar-seccion-list";
      llamadaApiToken(data, url, respuestaPerfilEst,ErrorGeneral,history,source);
    }    

    
    const veriSesion =  () => {            
      let auxValidacion=authUser()
      if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
        ||(usuID===undefined||usuID===''||usuID===null) )
      {   guardarUsuario([])				
        guardarGenerales([])    
        localStorage.clear();            	             
        history.push("/")	                	
      }
      else
      {   
        if (usuario.length==0) 
        { guardarUsuario({Nombre:usu,nivel:nvl});                                                     
        }                       
        llamadaPerfilLista()
        llenaMunGPS() 
        llenaPerfilEstrc()
        llamadaPlantilla()        
        llamarListSecc()
      }            
    }        
    veriSesion();	      
      
  return ()=> {
      source.cancel();
  }                
  },[actualizar])

  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setListPagina(datos.slice(0,tamMax))   
    }
    else
    {
      setListPagina(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination
      rowsPerPageOptions={false} style={{padding:0}}
      rowsPerPage={tamMax} count={datos.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  const seleccionado=(auxId,perfil)=>{
    setSelectedID(auxId)   
    //console.log({...perfil,MunId:muniSelect.Id,MunNom:muniSelect.Nom});
    setRegisSelect({...perfil,MunId:muniSelect.Id,MunNom:muniSelect.Nom})
    let aux1= generaCoordenadaDinam(perfil.Lat, perfil.Lon)
    if (aux1.lat!==0) {
      let auxSeleccionado=[]
      auxSeleccionado.push({coordenada:aux1,perfil:perfil.Nombre+"\n"+perfil.Tel})
      setAuxMapVar(auxSeleccionado)  
    }else{
      setAuxMapVar([])  
    }
    
  }
  
  function ordenIdColor(MuniNum){    
    let auxNvo =MuniNum
    if (auxNvo===25) //si es cuatitlan izcalli se cambia a 121
    { auxNvo=121
    }
    else
    { if (auxNvo>25 && auxNvo<=121 && auxNvo!==59) 
      { if (auxNvo===60) 
        { auxNvo=58   
        }
        else
        { auxNvo=auxNvo-1
        }
      }
    }
    if (auxNvo===108) 
    { auxNvo=20
    }    
    return auxNvo
  } 
  
  function tipoEdoProspecto(auxColorStd,etiqueta)
  {    
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'}} 
      >
        <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold", backgroundColor:auxColorStd}}>
          {etiqueta}
        </Card>
      </TableCell>    )
    return renglon
  } 
  
  const llenaMunGPS=()=>{
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");
    const data = qs.stringify({
      usuario:usuID,                                        
      fini:fInicio+'T00:00:00',
      ffin:fFinal+'T23:59:59',
      status:0,          
      municipio:muniSelect.Id,
      idDispositivo: auxDispV,
    });
    const url = "perfil-lista-municipio-gps";
    llamadaApi(data, url, respuestaMunGPS,ErrorGeneral,source,history);
  }  
 
  function respuestaMunGPS(auxiliar) {
    if (auxiliar[0].Id !== 0 ) {
      // console.log(auxiliar);   
      setMuniGps(auxiliar)    
    }
  }

  function abrirModalMapa(auxSelec){
    let fecha = moment.utc(auxSelec.Fecha).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00"
    ? "" : moment.utc(auxSelec.Fecha).format('DD/MM/YYYY HH:mm:ss')
    let titulo=`${auxSelec.Id}`;
    setTituloMapa(titulo)
    setSubtInfo(fecha)
    setRegisSelect(auxSelec)
    setInfoMapaMod(true)
  }
  
  function abrirModalInfo(auxSelec){
    setModalInfo(true)
    
    let titulo=`Registro ${auxSelec.Id} `;
    setTituloMapa(titulo)
    setRegisSelect(auxSelec)
  }  

  const celda=(alinear,estilo,contenido)=>{
    return (
    <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
      {contenido}
    </TableCell>
    )
  }
  
  const editarPerfil=(e,row,auxValida)=>{
    e.preventDefault()    
    setModalNvoImg(true);    
    console.log(row.Id);
    setNvoRegisPerfil({Nom:row.Nombre,llave:row.Id})      
  }

  function Renglon(props)
  {
    const { row } = props;      
    let leyenda=``
    let tipoColor="inherit"
    let auxValida=1
    if(row.Imagen&& row.Imagen.length > 0)
    {
      leyenda=`Imagen Cargada`
      tipoColor="primary"
      auxValida=0
    }
    const imgEstilo=selectedID === row.Id ? {width:18+'px',color:'white',marginTop:.2+'rem'} :{width:18+'px',marginTop:.2+'rem'}    
    const auxImg = parseInt(usuID) === 1 ?
      (<Tooltip title={leyenda}> 
        <IconButton   aria-label="agregar imagen" size="small" onClick={(e) => editarPerfil(e,row,auxValida)} 
          component="span">
          <ImageIcon style={imgEstilo} color={tipoColor} /> 
        </IconButton>
      </Tooltip> )
      : (<Tooltip title={leyenda}><ImageIcon style={imgEstilo} color={tipoColor} /></Tooltip>)                  
    const auxEdit=  <Tooltip title={`Editar Perfil`}>
      <IconButton   aria-label="add circle" size="small" 
        onClick={() => setModalEditPerfil(true)} component="span">
          {selectedID === row.Id ? 
            <CreateIcon style={{color:'white',width:18+'px'}} />
            :<CreateIcon style={{width:18+'px'}} color="primary"/>
          }
        </IconButton>                            
      </Tooltip>      
    return (   
    <TableRow onClick={ () => { seleccionado(row.Id,row); } }
      className={classes.tableRow} selected={ selectedID === row.Id}        
      classes={{ hover: classes.hover, selected: classes.selected }}        
    >
      {nvl === 1 ? celda("center",{width:10+'px'}, auxEdit):null}
      <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >        
        <Tooltip title={`Información de registro`}>
          <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalInfo(row)} component="span">
            {selectedID === row.Id ? 
              <InfoIcon style={{color:'white',width:18+'px'}} />
              :<InfoIcon style={{width:18+'px'}}color="primary"/>
            }
          </IconButton>                     
        </Tooltip>                                                                                                                                                       
      </TableCell>  
      <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
        <Tooltip title={`Ver Mapa`}>
          <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalMapa(row)} component="span">
            {selectedID === row.Id ? 
              <LocationOnIcon style={{color:'white',width:18+'px'}} />
              :(row.Lat===0   ? <LocationOnIcon style={{width:18+'px'}} color="error"/>
              :<LocationOnIcon style={{width:18+'px'}} color="primary"/>)
            }
          </IconButton>                            
        </Tooltip>                                                                                                                                              
      </TableCell>    
      {celda("center",{width:10+'px',paddingLeft:.3+'rem'}, auxImg)}
      {/* celda("center",{fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.Id) */}        
      <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}} >          
        {moment.utc( row.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
          ? "" : moment.utc( row.Fecha).format('DD/MM/YYYY HH:mm')}       
      </TableCell>
      {celda("center",{fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.SeccNum)}        
      {celda("center",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Tel)}        
      {celda("left",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.UserNom)}                        
      {celda("left",{width:220+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Nombre)}  
      {celda("left",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Email)}          
      {tipoEdoProspecto(row.StatusColor,row.StatusNom)}             
      <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:80+'px',fontSize:12+'px',paddingLeft:8+'px'}} >          
        {moment.utc( row.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
          ? "" : moment.utc( row.Nacimiento).format('DD/MM/YYYY')}       
      </TableCell>                                                                                                                                                              
    </TableRow>           
    )
  ;}
  
  function tablaCompleta(auxlista){  

  return(
  <TableContainer className={classes.container}>
  <Table stickyHeader aria-label="sticky table">
    <TableHead>
      <TableRow>       
        {nvl === 1 ? <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >              
        </TableCell> :null}     
        <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >              
        </TableCell>        
        {usuID && parseInt(usuID) === 1 ?<TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >     
      <IconButton onClick={()=>exportarArch(listaExcel," Lista x Municipio "+muniSelect.Nom)} size="small" >
        <Tooltip title="Exportar a Excel">
          <SaveIcon style={{color:'white'}} size="small" />
        </Tooltip>
      </IconButton>                                  
      </TableCell>
      :  <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >              
      </TableCell>
      }                                  
        <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >
          {auxlista.length}
        </TableCell>                          
        {columnas.map(row => {              
          return(  
          <TableCell style={{backgroundColor:fondo,color:'white'}} 
            key={row.id}    align={row.alinear} padding={'none'} 
          >
            {row.titulo}
          </TableCell>    
          )                
        })}           
      </TableRow>
    </TableHead>
    <TableBody>
      {auxlista.length!=0 ? (auxlista.map((row) => (
        <Renglon key={row.Id} row={row} />
      ))):null}
    </TableBody>
  </Table>
  </TableContainer>
  )}

  const vacia= <TableCell  className={classes.tableCell} align="center"> </TableCell> 
  const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:fondo,color:'white'}} 
              key={row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}                                                
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow} > 
          {vacia}
          {vacia}
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>                              
          {vacia}
          {vacia}
          <TableCell  className={classes.tableCell} align="center">Registros</TableCell>
          {vacia}
          {vacia}
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
  )}
     
  const contenido = sinDatos&&listPagina.length!==0 ?tablaCompleta(listPagina):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido ) 

  const modalMap = infoMapaMod ? <ModalMapa seleccionado={regisSelect} titulo={tituloMapa} subtitulo={subtInfo}
                                    setModalAbierto={setInfoMapaMod } modalAbierto={infoMapaMod}/> : null;     

  const modInfo = modalInfo ? <InfoPromo seleccionado={regisSelect} modalAbierto={modalInfo} listPerfil={datos}
                                setModalAbierto={setModalInfo} titulo={tituloMapa} /> : null;            
          
  const modNvoImg = modalNvoImg  && listPlantilla.length!== 0  ? 
                                <ModalNvoPerfilImg modalAbierto={modalNvoImg} setModalAbierto={setModalNvoImg} 
                                  titulo= {nvoRegisPerfil.Nom ? "Promovido "+nvoRegisPerfil.Nom:"Promovido"}  auxPerfImg={listPlantilla}
                                  nvoRegisPerfil={nvoRegisPerfil} actualizar={actualizar} setActualizar={setActualizar}
                                />:null         
  const modEditPerf = modalEditPerfil && listPlantilla.length!== 0 ? 
                                <ModalEditPerfil modalAbierto={modalEditPerfil} setModalAbierto={setModalEditPerfil} 
                                  titulo="Editar Perfil" perfil={regisSelect} listPerfil={datos}
                                  listMun={listMun} listSecc={listSecc}
                                />:null                                                              
  const paginacion = !espera&&listPagina.length!==0 && totalPaginas>1 ? generarFooter() : null;                                          
  return (
    <div style={{minWidth:50+'rem',maxWidth:55+'rem'}}>        
      {paginacion}            
      {tabla}       
      {modalMap}
      {modInfo}
      {modEditPerf}
      {modNvoImg}
    </div>
  )
}

export default TablaXMuni