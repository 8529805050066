import React,{useState, useEffect} from 'react';
import TablaAdmGeneral from './TablaAdmGeneral'
import {useHistory} from "react-router-dom";
import {Box,IconButton,Tooltip, Typography,
  Card,TextField, LinearProgress,
  RadioGroup,Radio,FormControlLabel,FormControl
} from "@material-ui/core";
import IntervaloSelect from './IntervaloTabla';
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from '@material-ui/icons/Search';
import CardPerfil  from './CardPerfil';
//import ContenedorMapa from './ContenedorMapa';
import MapDelegacion from '../MapaEdo';
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";

const arrBase={
  "Id": 0, "MunId": 0, "MunNom": "TODOS",
  "MunNum": 0, "UsrId": 0, "UsrNom": "",
  "UsrTel": "0","UsrUsr":""
}
const AdmGeneral = () => {
  let history = useHistory();
  const source = axios.CancelToken.source();
  const idusu = localStorage.getItem("UsuId") ? parseInt(localStorage.getItem("UsuId")):0;    
  const auxDispV =localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 
        ? localStorage.getItem('Dispvo'):'w8rf51v21dsd2cs'             
  const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
  //const[totalPerfil,setTotalPerfil]= useState({Valido:0,Pendiente:0,Rechazado:0});
  const[totalPerfil,setTotalPerfil]= useState([]);
  const[actualizar,setActualizar]=useState(true);
  const [arregloColor,setArregloColor] = useState([])    
  const [listMun,setListMun]=useState([])
  const [munSvgSelect,setMunSvgSelect]=useState(0)
  const [listEncargado,setListEncargado]=useState([])
  const [cargaList,setCargaList]=useState([])
  const [encargado,setEncargado]=useState([])
  const[nombre, setNombre]=useState("")
  const[filtro, setFiltro]=useState({Titular:"",Superior:"", coord:""})
  const{Titular,Superior,coord}=filtro
  const[listTitularEsp,setListTitularEsp]= useState([]);
  const[listTitular,setListTitular]= useState([]);
  const [listAuxTitu, setListAuxTitu] = useState([]);
  const [bandera,setBandera]= useState(0);
  useEffect(() => {
    llamadaListTitular(5)
    llamadaListTitular(11)     
         
    return () => {
      
    }
  }, [])
  
  useEffect(() => {      
    if (coord === "5") 
    {
      setListAuxTitu( listTitular)
    }
    if (coord === "11") {
      setListAuxTitu( listTitularEsp)
    }   
  }, [coord])

  function llamadaListTitular(auxCoord)  {            
    const url= `coordinador-municipal-titular-list-1-1`;                            
    const data = qs.stringify({                
        Usuario:idusu,           
        Tipo:auxCoord,               
        idDispositivo:auxDispV,
    });
                
    function respuesta (aux) 
    {  if (aux[0].Id != -1) 
      {   
        if (auxCoord===5) {
          setListTitular(aux)
        }
        if (auxCoord===11) {
          setListTitularEsp(aux)
        }

      }                                                                      
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
  };

  const llenalistEncarga = (auxTit) => {
    const data = qs.stringify({        
        Usuario: auxTit,
        idDispositivo: "987gyubjhkkjh",
    });
    const url = "coordinador-municipal-encargado-list";

    function respuesta(aux) {
      if (aux[0].Id!==-1 ) 
      {
        const nvoList=[arrBase, ...aux ]
        setListEncargado(nvoList)
      } 
      else 
      {  
        setListEncargado([])
      }
    }

    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargaList, history, source);
  }; 
  
    const generarLista = e =>{  
      setBandera(1) 
      e.preventDefault()    
      setTotalPerfil([])
      setArregloColor([])
      setActualizar(!actualizar)        
    }

  const generaCard=(seleccionado)=>{
    let auxMun=listMun.filter((muni)=>muni.Id===seleccionado)
    //console.log(auxMun);
    return(
    <Card style={{padding:.3+'rem'}}>
      <Typography>{auxMun.length > 0 ? auxMun[0].Nom : ""}</Typography>
    </Card>
    )
  }

  const guardarMuni=(e)=>{
    //console.log(e);
    if (e!== null && e!== undefined) 
    { setEncargado(e)            
    }
    else
    { setEncargado([])     
    }
 
  }

  const guardarSupr=(e)=>{    
        
    if (e!== null && e!== undefined) 
    {   setFiltro({...filtro, Titular : e })          
      llenalistEncarga(e.UsrId)
    }
    else
    {   setFiltro({ ...filtro, Titular  : "" })               
    }        
  }

  const onChangeRad=(e)=>{
    setEncargado([])
    setListEncargado([])    
    setFiltro({...filtro,[e.target.name] : e.target.value,  Titular  : ""  })      
           
  }

  const onChange = e => {
    setNombre( e.target.value)
  }
  const cardMuni= listMun.length!==0 && munSvgSelect!== 0 ? generaCard(munSvgSelect):null
  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={generarLista}>
            <Tooltip title="Buscar">
            <SearchIcon/>
            </Tooltip>
        </IconButton>                                                                                                                              
      </Box>    
      <Box display={"flex"}>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />               
        <FormControl component="fieldset" style={{marginLeft:.5+'rem',marginTop:".5rem" }}>          
          <RadioGroup  row aria-label="position"  defaultValue="top"
            name="coord" value={coord} onChange={onChangeRad}
          >   
            <FormControlLabel value={"5"}  label={"Municipal"}     
              control={<Radio color="primary" />} />                             
            <FormControlLabel value={"11"}  label={"Estatal"}             
              control={<Radio color="primary" />} />                                                                            
          </RadioGroup>                     
        </FormControl>  

     { listAuxTitu.length!==0?
        <Autocomplete
          id="sel-Titular" size='small' style={{ width: 25+'rem',marginTop:".5rem" }}
          onChange={(e,option)=>guardarSupr(option)}  value={Titular }      
          getOptionLabel={(option) => option.UsrNom} name="Titular "             
          noOptionsText={"Sin coincidencias"} options={listAuxTitu}
          renderInput={(params) => (
            <TextField {...params} size='small' 
              label="Titular" variant="outlined" />
          )} 
        />
      :null}
      </Box>
    </Box>   
    <Box mb={1} display={"flex"} >
      {  listEncargado.length!==0 ?
        <Autocomplete
          id="encargados-autocomplete-01" size='small'
          onChange={(e,option)=>guardarMuni(option)}
          options={listEncargado}
          getOptionLabel={(option) => `${option.MunNom} - ${option.UsrNom} ${option.UsrUsr!==""?"("+option.UsrUsr+")":""}`}
          style={{ width: 40+'rem' }} noOptionsText={"Sin coincidencias"}
          renderInput={(params) => (
            <TextField {...params} 
              label="Encargados" variant="outlined" />
          )}
        />
        :<LinearProgress/>}
        <Box ml={1} width={600}>
          <TextField id="nombre-txt" name="nombre" label="Nombre" fullWidth size="small"
            value={nombre} onChange={onChange} variant="outlined"/>  
        </Box>
        
    </Box>  
  
    
      
    {/* infoNvlCoord.length > 0 ? nvlInfo(infoNvlCoord[0]) :null */}
    <Box>
      {totalPerfil.length !== 0 ? 
      <CardPerfil valor={totalPerfil} />
      :null}
    </Box>
    {bandera===1 && coord!==""?
     <Box display={"flex"} row  width={"100%"} >
      <Box width={"71%"} style={{minWidth:63+'rem'}} >
      <TablaAdmGeneral fSelect={fSelect} actualizar={actualizar} setActualizar={setActualizar}
          setTotalPerfil={setTotalPerfil} setArregloColor={setArregloColor}
          setListMun={setListMun} listMun={listMun} 
          encargado={encargado} nombre={nombre} filtro={filtro}
        />
      </Box>
      {arregloColor.length !== 0 ?
      <Box display={"flex"} flexDirection="column"  style={{minWidth:20+'rem'}} >
      {/* <span  >
        {cardMuni}
        <ContenedorMapa coloreados={arregloColor} setMunSvgSelect={setMunSvgSelect}/>
      </span> */}
        <span style={{marginLeft:1+'rem'}} >
          <MapDelegacion arregloColor={arregloColor}/>
        </span>
      </Box>
      :null
      }
    </Box>
    :null}
  </div>
  );
};

export default AdmGeneral;