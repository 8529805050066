import React , {useState,useEffect} from 'react'
import Modal from '../generales/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {Button,Card, Box, Typography,FormControl,InputLabel,Select,MenuItem} from '@material-ui/core'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import {llamaApiCargaToken,ErrorGeneral,llamaApiFormDat2 } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display:'flex',
   flexDirection:'column'
  },

  formSelect: {
    width:'12em'
  },

  formDesc:{
      marginTop:'1.7em',
      width:'30em',
      height:'4em',
      display:'flex',
  },
  formImg:{
    marginTop:'.5em',
    width: 20+'rem',
    height:23+'rem',
    borderRadius: '.6rem'
  },
  cardDatos:{
    width:'100%',
    height:28+'rem',
},
}));

export default function EditarImagen({modalAbierto,setModalAbierto,seleccionado,titulo,municipio,procSel,getDatos,setModalFotos}){
  const classes = useStyles();
  const history = useHistory();
  const source = axios.CancelToken.source(); 
  const auxId =localStorage.getItem('UsuId') ;  
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'
  const [espera,setEspera]=useState(false)
  const [espera2,setEspera2]=useState(false)
  const [mensaje, setMensaje] = useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)
  const [botonesDeshab,setBotonesDeshab] = useState(false)
  const [aviso, setAviso] = useState(false);
  const [imgPrimaria, setImgPrimaria] = useState("./partidos/partido_pan.svg");
  const [imgSecundaria,setImgSecundaria] = useState("./partidos/partido_pan.svg")
  const [portadaEnviar, setPortadaEnviar] = useState();
  const [contraEnviar,setContraEnviar] = useState()
  const [reiniciar,setReiniciar] = useState(false)
  const [progresoEnvio, setProgresoEnvio] = useState(0);
  const [listaCasillas,setListaCasillas]=  useState([]);       
  const [registro,setRegistro] = useState({Casilla:null,});
  const {Casilla} = registro;   
  useEffect(()=>{
    //console.log(seleccionado);
    getSeccion()
    return ()=> {
      source.cancel();
      }
  },[seleccionado])

  const getSeccion = () => {          
    const data = qs.stringify({
        municipio:municipio.muniId,
        idDispositivo: auxDispV,
    });
    const url = "votacion-web-municipio-secccasi-lista";
    function respuesta(auxSecciones) {
      if (auxSecciones)
      {   
        const dato= auxSecciones.filter(  (registro) => registro.seccId === seleccionado.seccId )                    
        let arreCasilla=[];                      
        dato.forEach((seccion) => 
        {            
          arreCasilla.push({ casillaId : seccion.casillaId, 
            casillaNom : seccion.casillaNom })                                                                                                          
        })
        setListaCasillas(arreCasilla);
      }
      
    }
    llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
  };    

  const onChangeImgPrimaria = (e) => {
    const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png)$/;      
    let regex2 = new RegExp("[$%@#&+(){}/:;!?,<>= ]", "g");      
    if ( e.target.files[0] && e.target.files[0].name.toLowerCase().match(regex) &&
    e.target.files[0].size < 10 * 1024 * 1024
    ) {
    let auxCode = uuidv4();
    //let auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)
    let arch = e.target.files[0];
    let auxNombre = auxCode + arch.name.slice(arch.name.lastIndexOf("."), arch.name.length);
    let auxNombre2 = auxNombre.replace(regex2, ""); //'probandoNuevo.pdf'        
    let myNewFile = new File([arch], auxNombre2, { type: arch.type });      
    setImgPrimaria(URL.createObjectURL(e.target.files[0]));
    setPortadaEnviar(myNewFile);
    setBtnDeshabilitado(false);
    } else {
    let mensaje = "Formato de archivo incorrecto, seleccione una imagen tipo jpg,jpeg o png";
    if (e.target.files[0]&& e.target.files[0].size >= 10 * 1024 * 1024) {
        mensaje = "El tamaño del archivo debe ser menor a 10mb.";
    }
    toast.warning(mensaje, {
        pauseOnFocusLoss: false,
        theme: "colored", toastId: `incorrecto-selPrim`,
    });
    setImgPrimaria("T_LOGO_1.svg");
    setPortadaEnviar();
    }
  };

    const onChangeImgSecundaria = (e) =>{
        const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png)$/
        if(e.target.files[0] && e.target.files[0].name.toLowerCase().match(regex) &&
        e.target.files[0].size < 10 * 1024 * 1024
        ){
        let auxCode=uuidv4()       
        //let auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)   
        let arch = e.target.files[0]
        let auxNombre=auxCode+arch.name.slice(arch.name.lastIndexOf("."),arch.name.length)
        let myNewFile = new File([arch], auxNombre, {type: arch.type});      
        setImgSecundaria(URL.createObjectURL(e.target.files[0]))
        setContraEnviar(myNewFile)
        setBtnDeshabilitado(false)
        }
        else
        { let mensaje = "Formato de archivo incorrecto, seleccione una imagen tipo jpg,jpeg o png";
        if (e.target.files[0]&& e.target.files[0].size >= 10 * 1024 * 1024) {
            mensaje = "El tamaño del archivo debe ser menor a 10mb.";
        }
        toast.warning(mensaje,{
            pauseOnFocusLoss: false,
            theme: "colored", toastId: `incorrecto-selSec`
        }); 
        setImgSecundaria("T_LOGO_1.svg" )
        setContraEnviar()
        }
    }

    const subirImagen = (auximg) => {  
      const formData = new FormData();      
      setBtnDeshabilitado(true)
      setBotonesDeshab(true)        
      formData.append('usuario', auxId)        
      formData.append('tipo',1)
      formData.append('casilla',Casilla)
      formData.append('periodo',procSel)
      formData.append('seccion', seleccionado.seccId)                           
      formData.append('idDispositivo', '45vf2d1vdf21d2f')
      formData.append('multi-files',auximg)        
      const url = 'votacion-evidencia-envio';

      function respuesta(aux) {
        if(aux.success)
        { const mensaje = " Subida exitosamente";
          setTipoAdvertencia("success");
          setMensaje(mensaje);
          setAviso(true);               
          setModalAbierto(false)
          setModalFotos(true)
          toast.success(mensaje,{
            pauseOnFocusLoss: false,
            theme: "colored",
            toastId: `exito-env${1}`
          })
          //getDatos()
        
        }
      }            
      llamaApiFormDat2(formData, url, respuesta,ErrorGeneral,setEspera,history,source,auximg.size,
        process.env.REACT_APP_EDO,"VOTACION",setProgresoEnvio)     
    }

  function verificarImg (img){
    let validado=false;
    if(img && img.size)
    { validado = true;
    }
    else
    {
      validado = false;
      setMensaje("Seleccione una imagen");
      setAviso(true);
      setTipoAdvertencia("error");
    }
    return validado;
  }


  /*   function handleSubmission (){
      if(verificarImg(portadaEnviar)||verificarImg(contraEnviar)){
        if(portadaEnviar){
           subirImagen(portadaEnviar,"FRENTE");
        }
        if(contraEnviar){
           subirImagen(contraEnviar,"RETRATO")
        }
      }else{
        setMensaje("Seleccione una imagen ");
        setAviso(true);
        setTipoAdvertencia("error");
      }
    } */

  function valCasi() {
    let respuesta=true
    if (Casilla===null || Casilla===0) 
    { setMensaje("Seleccione una Casilla");
      setAviso(true);
      setTipoAdvertencia("error");       
      respuesta=false 
    }
    return respuesta
  }

  function handleSubmission (){
    if(valCasi() && verificarImg(portadaEnviar) ){
      if(portadaEnviar){
        subirImagen(portadaEnviar,"FRENTE");
      }
    }
  }

  function onChangeCasilla(e){                   
    setRegistro({
      ...registro, 
      Casilla:e.target.value
    })    
  }

  return(
  <Modal
    titulo={titulo} modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={handleSubmission}
    mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
    aviso={aviso} btnDeshabilitado={btnDeshabilitado}
    setAviso={setAviso} tamanio={'l'} cargando={espera}       
  >
    <form className={classes.root} noValidate autoComplete="off"> 
      {!espera2 && listaCasillas.length!==0 ? 
        <FormControl className={classes.camposCasilla} style={{width:"10rem", marginBottom:".5rem"}}>
          <InputLabel id="tipo">Casilla</InputLabel>
            <Select //variant="outlined"
              label="casilla" id="casilla" name="casilla"
              value={Casilla} onChange={onChangeCasilla}
            >
                {listaCasillas.map((cas, index) => (
                <MenuItem value={cas.casillaId} key={cas.casillaId}>
                    <em>{cas.casillaNom}</em>
                </MenuItem>
                ))}
            </Select>
        </FormControl>
      :null}      
      <Card className={classes.cardDatos} style={{}}>
        {/* <Typography id="primaria" style={{textAlign:'center'}}><b>FRENTE</b></Typography> */}
        <Box display="flex" flexDirection="column">
          <img className={classes.formImg} hidden={!imgPrimaria}  
            src={imgPrimaria ? imgPrimaria : ""} alt="Imagen Primaria"
          />
          <Box style={{marginTop:'.5rem',display:'flex',justifyContent:'center'}}>
            <input type="file" onChange={(e)=>onChangeImgPrimaria(e)} hidden id={"imgPrimaria"}
              accept="image/jpg,image/jpeg,image/png"disabled={botonesDeshab}  />
            <label htmlFor={"imgPrimaria"}>
              <Button variant="contained" component="span" color="primary" size='small' disabled={botonesDeshab}  >
                Cargar IMG
              </Button>
            </label>
          </Box>
        </Box>
      </Card>
  {/*     <Card className={classes.cardDatos}>
        <Typography id="secundaria" style={{textAlign:'center'}}><b>RETRATO</b></Typography>
        <Box display="flex" flexDirection="column">
          <img className={classes.formImg} hidden={!imgSecundaria}  
            src={imgSecundaria ? imgSecundaria : ""} alt="Imagen Secundaria"
          />
          <Box style={{marginTop:'1em',display:'flex',justifyContent:'center'}}>
            <input type="file" onChange={(e)=>onChangeImgSecundaria(e)} hidden id={"imgSecundaria"}
              accept="image/jpg,image/jpeg,image/png" disabled={botonesDeshab}  
            />
            <label htmlFor={"imgSecundaria"}>
              <Button variant="contained" component="span" color="primary" size='small' disabled={botonesDeshab}  >
                Cargar IMG
              </Button>
            </label>
          </Box>
        </Box>
      </Card> */}
    </form>
  </Modal>
  )
}
