import React,{useState,useEffect} from 'react';
import {Box, IconButton,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField,
    Tooltip,Card,TablePagination,Select, FormControl,InputLabel,
    MenuItem} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import moment from 'moment';
import Viewer from 'react-viewer';
import InciInformacion from './InciInformacion'
import NuevaIncidencia from './NuevaIncidencia';
import PaginacionTab from '../layout/PaginacionTab'
import MenuIcon from '@material-ui/icons/Menu';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { useHistory } from "react-router-dom";
import ModalAgregarEvidencia from './ModalAddEvidencia'
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from 'axios';
import qs from 'qs';
import {llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      minWidth: '800px',
      maxWidth:'70%',
      minHeight: 150,
     // backgroundColor:'white',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    buscador:{
        width:'30em',
        marginLeft:'.8em'
    },
    elementos:{
        alignContent:'center',
        padding: 0.2 +'rem'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:  process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

  const columnas=[
    {   id:8,
        titulo:"",
        alinea:'center'
    },
    {
        id:2,
        titulo:"Foto",
        alinea:'center'
    },
    {
        id:7,
        titulo:"Fecha",
        alinea:'center'
    },
    {
        id:3,
        titulo:"Hora",
        alinea:'center'
    },
    {
        id:4,
        titulo:"Secc",
        alinea:'center'
    },
 /*    {
        id:5,
        titulo:"Casilla",
        alinea:'center'
    }, */
    {
        id:6,
        titulo:"Descripción",
        alinea:'center'
    },
];

export default function InciTablaRes({lista,listTipo,objSelec,getResSel, tipo,setTipo,setActualizar,actualizar,getResSel2}){
    const alturaTabla = window.innerHeight<937 ? (lista.length>300 ? window.innerHeight-660 : window.innerHeight-520) 
                                               : (lista.length>300 ? window.innerHeight-700 : window.innerHeight-620)
    const classes = useStyles();
    const history = useHistory();
    let auxArreglo=[]
    const fondo=process.env.REACT_APP_Fondo_Color
    const source = axios.CancelToken.source();
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
    const [listaInciden,setListaInciden] = useState(lista)
    const [seleccion,setSeleccion]=  useState(null);      
    const [incidencia,guardarIncidencia] = useState({ nombre:''});    
    const [listaFotos,setListaFotos] = useState([])
    const [modalFotos,setModalFotos] = useState(false);
    const [modalEvidencia,setModalEvidencia] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [modalNvo, setModalNvo] = useState(false);
    const [totalPaginas,setTotalPaginas]=useState(1);
    const [numPagina,setNumPagina]=useState(0);
    const [seleccionado,setSeleccionado]=useState(null);
    const {nombre} = incidencia;
    useEffect(()=>{
        console.log();
        if(lista.length>300)
        {   let auxTam = lista.length;
            if(auxTam%300===0)
            {
                let pagina=Math.trunc(auxTam/300)
                setTotalPaginas(pagina)   
            }
            else
            {   let pagina=Math.trunc(auxTam/300)
                pagina=pagina+1;
                setTotalPaginas(pagina)
            }
            setNumPagina(0) 
            setListaInciden(lista.slice(0,300))
        }
        else
        {   setTotalPaginas(1)
            setNumPagina(0)
            setListaInciden(lista)
        }
    },[lista])

    useEffect(() => {
        console.log(seleccionado);

    }, [seleccionado])
    
    const exportarArch = () => {
        let today = new Date();                    
        let fec = moment(today);
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        const auxTipo=seleccionado? seleccionado.Nombre:objSelec.TipoNom
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Incidencias de tipo "+auxTipo+".xlsx")
    };

    function selecFila(reg){                
        setSeleccion(reg);
    }
    
    function filtro(){        
        let auxCadena=nombre.trim();                 
        auxArreglo=[]               
        let info=listaInciden.filter((dat)=>dat.Descr.search(auxCadena) !== -1)    
        return generarTabla(info);
    }

    const getFotos = (inci) => {            
        const data = qs.stringify({
            incidencia: inci.Id,     
            idDispositivo: auxDispV,
        });
        const url = "incidencia-evidencia-list";
        function respuesta(aux) {
            if (aux)
            {   setListaFotos(aux);         
                setModalFotos(true);                                                                
            } 
            else
            {   setListaFotos([]);
            }       
        }
        
        if (inci.Foto>0) 
        {   llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source) 
        }        
    };
    

    function onChangePagina(event, newPage){
        event.preventDefault();
        if (newPage ===0) 
        {   setListaInciden(lista.slice(0,300))   
        }
        else
        {   setListaInciden(lista.slice(newPage * 300, newPage * 300 + 300))   
        }
        setNumPagina(newPage)
    }

    function abrirModalInfo(){
        setModalInfo(!modalInfo)        
    }

    function abrirModalEviden(){
        setModalEvidencia(true)        
    }

    const abrirModalNuevo=()=>{
        setModalNvo(true)
    }

    function sinDatos(){
        return(
        <TableContainer className={classes.container} style={{height: listaInciden.length>0 ? alturaTabla : 150}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow >                                        
                    <TableCell padding="none" align="center" style={{backgroundColor:fondo,color:'white'}}>
                    {listaInciden.length}    
                    </TableCell>                                  
                    {columnas.map(row=>{
                        return <TableCell padding='none' align={row.alinea} key={row.id} 
                            style={{backgroundColor:fondo,color:'white'}}>{row.titulo}</TableCell>
                    })}                                    
                </TableRow>                        
            </TableHead>  
            <TableBody> 
                <TableRow>
                    <TableCell padding='none' align="center" style={{width:2,paddingLeft:'.2rem'}}></TableCell>
                    <TableCell padding='none' align="center" style={{width:25,paddingLeft:'.2rem'}}></TableCell>
                    <TableCell padding='none' align="center" style={{width:25,paddingLeft:'.2rem'}}></TableCell>
                    <TableCell padding='none' align="center">Sin</TableCell>               
                    <TableCell padding='none' align="center">Resultados</TableCell>
                    <TableCell padding='none' align="center"></TableCell>
                    <TableCell padding='none' align="center">Disponibles</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const celda = (alinear, estilo, contenido) => {
        return (<TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo}>
            {contenido}
        </TableCell>)
    }
    
    function generarTabla(lista) {        
        let numRenglon=0;
        const tot= Object.keys(lista).length;
        let auxi2=["#","Horario","Sección","Casilla","Descripción"];
        auxArreglo.push(auxi2);
        if(tot===0)
        {   const auxi3=["","Sin","Incidencias" ,"de este ","tipo"]
            auxArreglo.push(auxi3);            
        }             
        return(
        <TableContainer className={classes.container} style={{height: listaInciden.length>0 ? alturaTabla : 150}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow >                                        
                    <TableCell padding="none" align="center" style={{backgroundColor:fondo,color:'white'}}>
                        {listaInciden.length}    
                    </TableCell>                                  
                    {columnas.map(row=>{
                        return <TableCell padding='none' align={row.alinea} key={row.id} style={{backgroundColor:fondo,color:'white'}}>{row.titulo}</TableCell>
                    })}                                    
                </TableRow>                        
            </TableHead>  
            <TableBody> 
              {  lista.map((inciden,index) => {
                    const auxi=[ index+1 , inciden.Horario , inciden.SecNom,  inciden.Descr ]
                    auxArreglo.push(auxi);
                    numRenglon++;     
                    const bandera=seleccion?.Id === inciden.Id ? true :false
                    return(                                                                                                                  
                    <TableRow className={classes.tableRow} selected={bandera}  key={numRenglon}
                        classes={{ hover: classes.hover, selected: classes.selected }} onClick={ () => selecFila(inciden) }
                    >               
                        {celda("center",{width:20}, <Tooltip title="Agregar Evidencia">
                            <IconButton  component="span" onClick={() => abrirModalEviden()}>
                                <AddPhotoAlternateIcon fontSize="small" color={bandera ?"inherit":"primary"} 
                                    style={{color:bandera?"white":"" }}
                                />
                            </IconButton>
                            </Tooltip> )}                                                                                           
                        <TableCell padding='none' align="center" className={classes.tableCell} style={{width:20}}>
                            <Tooltip title="Información de la Incidencia">
                                <IconButton  component="span" onClick={() => abrirModalInfo(inciden)}>
                                    <MenuIcon  fontSize="small"  color={bandera ?"inherit":"primary"} 
                                        style={{color:bandera ?"white":"" }}
                                    />
                                </IconButton>
                            </Tooltip>          
                        </TableCell>                                 
                        <TableCell padding='none' align="center" className={classes.tableCell} style={{width:20}}>
                            {inciden.Foto>0 ? 
                                <Tooltip title="Galería de Fotos">
                                    <IconButton size="small" component="span" onClick={() =>  getFotos(inciden)}> 
                                       <PhotoLibraryIcon fontSize="small"  color={bandera ?"inherit":"primary"} 
                                            style={{color:bandera?"white":"" }}
                                       />                                         
                                    </IconButton>
                                </Tooltip>  
                            :null}      
                        </TableCell>                                        
                        {celda("center",{fontSize:'12px',width:1.5+'rem'}, 
                            <b>{inciden.FCaptura ?
                            (moment.utc(inciden.FCaptura).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
                                ? "" : moment.utc(inciden.FCaptura).format('DD/MM/YYYY')) : null}</b>
                        )}                          
                        {celda("center",{width:2+'rem'}, <b>{inciden.Horario}</b>)}  
                        {celda("center",{fontSize:'12px',width: 1.2+'rem'}, inciden.SecNom)}
                        {/* celda("center",{fontSize:'12px',width:1.5+'rem'}, inciden.CasillaNom) */}
                        {celda("left",{fontSize:'14px',width:16+'rem',paddingLeft:'.4em',fontWeight:500}, 
                            inciden.Descr.length>150?(inciden.Descr.substring(0,150)):(inciden.Descr))}                                                                                                                                                                  
                    </TableRow>              
                    );                        
            })}
            </TableBody>
        </Table>
        </TableContainer>
        )                
    }
           
    const onChange = e =>
    {         
        const expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        const expMenos = new RegExp('-');
        const expMas = new RegExp('[+]');     
        const expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            guardarIncidencia({
                ...incidencia, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }        
    };

    function generarFooter(){
        return(
        <TablePagination
            rowsPerPageOptions={false}
            colSpan={3} rowsPerPage={300}
            count={lista.length} page={numPagina}
            lang='es'  onPageChange={onChangePagina}
            ActionsComponent={PaginacionTab}
            labelDisplayedRows={
                ({ from, to, count }) => {
                return '' + from + '-' + to + ' de ' + count
                }
            }
        />)
    }

    const onChangeSel=(e)=>{           
        if (e!== null && e!== undefined) 
        {     
            setTipo( e.target.value )    
            const filtro=  listTipo.filter((auxTipo)=>auxTipo.Id===e.target.value)
            console.log(filtro);
            setSeleccionado(filtro[0])
            
         getResSel(e.target.value )                      
        }
        else
        {   setTipo(null)           
            setSeleccionado(null)
        }   
           
    }  

    const listaTip= listTipo.length!==0 ?                    
    <FormControl size='small'  style={{ minWidth:14+'rem'}} >
        <InputLabel size='small' variant="outlined" id="muni-label">
            Tipo
        </InputLabel>
        <Select  size='small' variant="outlined" 
            label="Tipo" id="muni-select2" name="tipo"
            value={tipo} onChange={onChangeSel}
        >
            {listTipo.map(( edo ) => {                        
                return(
                <MenuItem size='small' value={edo.Id} key={edo.Id}>
                    <em>{edo.Nombre }</em>
                </MenuItem>
                )
            } )}                    
        </Select>
    </FormControl>    
    :   null
    const contenido = incidencia? (filtro()):(generarTabla(listaInciden));
    const validaCont = listaInciden.length!==0 ? contenido : sinDatos();
    const paginacion = totalPaginas>1 ? generarFooter() : null;
    const modEvi = modalEvidencia ? <ModalAgregarEvidencia abrirModal={modalEvidencia} setAbrirModal={setModalEvidencia} 
                                        incidencia={seleccion}  getResSel={getResSel}  
                                    /> :null
    const modNvo = modalNvo ? <NuevaIncidencia titulo={"Nueva Incidencia"} modalAbierto={modalNvo} setModalAbierto={setModalNvo}/> : null;
    const modInfo = modalInfo ? <InciInformacion seleccionado={seleccion} modalAbierto={modalInfo} setModalAbierto={setModalInfo} titulo={objSelec.TipoNom+" "+ seleccion.Id}/> : null;
    const modFotos = listaFotos.length!==0 ? <Viewer visible={modalFotos} onClose={() => { setModalFotos(false); } }
                                                images={
                                                    listaFotos.map((imagen,index)=>{
                                                    return({src: imagen.Link, alt: ''})
                                                })} zIndex={2000}
                                            />
                                            :null
    const prueba=()=>{
        console.log(seleccionado);
        console.log(seleccionado,objSelec);
        getResSel2(seleccionado? seleccionado.Id:objSelec.TipoId)
    }
    return (
    <div style={{marginTop:'.5em'}}>
        <Card style={{height:'3.5rem',marginBottom:'.5rem',width:'510px'}}>
            <Box display="flex" flexDirection="row" alignContent={"center"} style={{marginTop:".65rem",marginLeft:".5rem"}}>
                {/* <TextField className={classes.buscador}
                    disabled={listaInciden.length===0} name="nombre"                         
                    placeholder="Buscar Incidencia" value={nombre}
                    onChange={onChange}
                />    */}
                {listaTip}
                {/* listaInciden.length!==0 ?
                <IconButton  onClick={prueba} 
                    style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}}>  
                    <Tooltip title="Recuperar Incidencias">
                        <RefreshIcon color="primary"/>
                    </Tooltip>
                </IconButton>
                :null */}
                <IconButton  onClick={exportarArch} style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}}>
                    <Tooltip title="Exportar Incidencias">
                        <SaveIcon color="primary"/>
                    </Tooltip>
                </IconButton>
               {/*  <IconButton  onClick={abrirModalNuevo} style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}}>
                    <Tooltip title="Nueva Incidencia">
                        <AddCircleOutlineIcon color="primary"/>
                    </Tooltip>
                </IconButton> */}
            </Box>
        </Card>
        {modEvi}	            
        {validaCont}
        {paginacion}
        {modInfo}
        {modFotos}
        {modNvo}
    </div>
    );
}
 