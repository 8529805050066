import React,{ useState } from 'react'
import {useHistory} from "react-router-dom";
import ModalInformativo from '../generales/ModalInformativo'
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import { Box, IconButton, TextField, Typography } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import SelectEdo from '../generales/SelectEdo';
const BusqSeccion = ({modalAbierto,setModalAbierto,}) => {
  const history = useHistory();
  const source = axios.CancelToken.source();
  const[aviso,setAviso]=useState(false)
  const[espera,setEspera]=useState(false)
  const[textoSecc,setTextoSecc]=useState("")
  const[respuesta,setRespuesta]=useState("")
  const [edo,setEdo]=useState('')

  const llenalistMun = (auxSecc) => {
    const data = qs.stringify({   
      Estado:edo,     
      Seccion: auxSecc,
      idDispositivo: "987gyubjhkkjh",
    });
    const url = "/lugar/v2/seccion/muni-edo";
  
    function respuesta(aux) {
      if (aux && aux[0].MuniNom ) 
      {
        setRespuesta(`${aux[0].MuniNom} ${aux[0].MuniNum}` )
      }       
    }
  
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
  }; 

  const onChange = e => {
    setTextoSecc( e.target.value)
  }

  const Busqueda=()=>{
    if (edo !== "" && textoSecc!=="") {
      llenalistMun(textoSecc)
    }
  }

  const onChangeSlct=(e)=>{            
    if (e!== null && e!== undefined) 
   {  setEdo(e.target.value)   
      setTextoSecc("")
   }
   else
   {  setEdo("")          
   }        
  }

  return (
  <ModalInformativo   titulo="Busqueda de Sección"
    modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto}
    tamanio="sm"
  >
    <Box display={"flex"} style={{alignItems:"center"}}>
      <Box mr={1}>
        <SelectEdo edo={edo} onChangeSlct={onChangeSlct} tipo="outlined" />
      </Box>  
      <Box  width={100}>
        <TextField id="textoSecc-txt" name="textoSecc" label="Sección" fullWidth size="small"
          value={textoSecc} onChange={onChange} variant="outlined" style={{width:"5rem"}}/>  
        
      </Box>
      <IconButton onClick={Busqueda} style={{marginLeft:"1rem"}}>
        <ReplayIcon/>
      </IconButton>
      { respuesta!==""? 
      <Box ml={1}  width={400} >
        <Typography variant='body2'>
          {`${respuesta}`}
        </Typography>
      </Box>:null}
    </Box>
  </ModalInformativo>
  )
}

export default BusqSeccion