import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card,Box, Typography, TablePagination} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";

const useStyles = makeStyles({
    estiloCard:{
      width: '38rem',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      // color:'#4a4a4a',
      display:'block',
      padding:'.5rem',
      maxHeight:'100%',
      minHeight: 280,
      marginRight:'.5rem'
    },
    tablaContainer: {
      width: '37.5rem',
      maxHeight: '28.5rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    totalEstilo:{
      fontSize:'1.25rem',
      fontWeight:'bold'
    },
    tituloEstilo:{
      paddingBottom:'.25rem',
      fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
 
export default function TablaPromovidos({listAv}){    
  const classes = useStyles();      
  const tamMax=200
  const [datos,setDatos] = useState([]);     
  const [registro,setRegistro] = useState([]);     
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,setListPagina]=useState([]);
  const auxCol=process.env.REACT_APP_Fondo_Color  

  useEffect(()=>{    
    //console.log(listAv);
    if (listAv.length!==0 &&listAv.secciones) 
    { 
      let nvoArr =[]
      listAv.secciones.forEach(element => {
        if (element.lista.length!==0) {
          element.lista.forEach(prom => {
            nvoArr.push(prom)    
          });
        }
        
      });    
      numeroPag(nvoArr,tamMax,setTotalPaginas,setNumPagina,setListPagina)      
      setDatos(nvoArr)  
   
    }
    else
    { setDatos([])
    }  
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAv])          

   const onChangeTable =(regis)=>{       
    setRegistro(regis)                           
  }  

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline,estilo})=> celda(aline?aline:"center", {...estilo,backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 


  const formatoNom=(registro)=>{
    const respuesta=`${registro.Nom} ${registro.Pat} ${registro.Mat}`
    return primerformato(respuesta)
  }

  const primerformato = str => {
    return str.toLowerCase().replace(/\b[a-z]/g, c => c.toUpperCase())
  }

  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setListPagina(datos.slice(0,tamMax))   
    }
    else
    {
      setListPagina(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination 
      rowsPerPageOptions={false}  style={{padding:0}}
      rowsPerPage={tamMax} count={datos.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >            
        <TableRow>         
          
          <CelTitl index={6} titulo={"Sección"} aline={"center"} />         
          <CelTitl index={7} titulo={"Teléfono"}  estilo={{fontSize:"13px"}} aline={"center"} />       
          <CelTitl index={5} titulo={"Nombre"} aline={"center"} />         
        </TableRow>        
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.id === elemnto?.id ?true:false                                              
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ onChangeTable }  
        > 
                  
          {celda("center", {width:"2.4rem", fontSize:"12px"}, elemnto.sec,index+5) }
          {celda("center", {width:"2.4rem", fontSize:"12px"}, elemnto.tel,index+6) }  
          {celda("left", {width:"20rem", fontSize:"12px"}, `${elemnto.nom} ${elemnto.pat ??""} ${elemnto.mat??""}` ,index+2) }   
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
                       
  const llamada= listPagina.length !== 0 ?(TablaEstr(listPagina)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
  const paginacion = listPagina.length!==0 &&totalPaginas>1 ? generarFooter() : null;                                                                                                                                             
  return (
  <Box display={'flex'} flexDirection="row" style={{height: '33rem' }}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-100}}>  
    {paginacion}                      
      {/* <Typography variant="body1" component="body1"></Typography>  */}
      {llamada}   
    </Card>

  </Box>                    
  );
}