import React,{useState,useEffect} from 'react';
import {Bar} from 'react-chartjs-2';
import {Card,Box,} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    //paddingRight:'3rem'
    height:4.6+'rem'
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    //color:'#4a4a4a',
  },
  tituloGraf:{
    paddingTop:'0rem',
    textAlign:'center'
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function Graficas({valor}){
  const classes = useStyles();
  let color1= '#19A11E';  //ff1744  
  let color2= '#FBBE06';
  let color3='#E91E63'    
  let color4='#FB7106' 
  let textValid ="";
  let textPend ="";
  let textRech ="";  
  let textAvan=""
  const [totalResta,setTotalResta] = useState(0)
  const [valorLocal,setValorLocal] = useState([])

  useEffect(()=>{
    if (valor !==0) { 
      let auxRes = valor.Meta- (valor.Pendiente+valor.Valido)
      setTotalResta(auxRes)   
      setValorLocal(valor)    
    } 
  },[valor])
    if (valor !==0) { 
      textValid =valor.Valido+" Valido";
      textPend =valor.Pendiente+" Pendiente";
      textRech =valor.Rechazado+" Rechazado";  
      textAvan =valor.Meta+" Meta";                      
    }
      
    const data = {
      labels: [
        textValid,
        textPend,textRech
      ],
      datasets: [{
        data: [valor.Valido, valor.Pendiente,valor.Rechazado],
        backgroundColor: [
        color1,
        color2,color4
        
        ],
        hoverBackgroundColor: [
          color1,
          color2
        ]
      }]
    }; 
    const data3 = {
      labels: [
        textValid,
        textPend,textRech,textAvan
      ],
      datasets: [{
        data: [valor.Valido , valor.Pendiente ,valor.Rechazado,valor.Meta ],
        backgroundColor: [
        color1,
        color2,color3,color4   
        ],
        hoverBackgroundColor: [
          color1,
          color2
        ]
      }]
      }; 
    const data2 = {
      labels: [ 'Avance'   ],
      datasets: [
        { label:'Válido',
          data:[valorLocal.Valido],
          backgroundColor:color1,         
        },
        { label:'Pendiente',
          data:[ valorLocal.Pendiente],
          backgroundColor:color2,
          
        },  
        { label:'Meta',
          data:[totalResta],
          backgroundColor:color4,         
        }, 
      ],
    }; 
  
    const dataSinPerfil = {
      labels: [ 'Avance'  ],
      datasets: [      
        { label:'Meta',
          data:[valorLocal.Meta],
          backgroundColor:color4,         
        }, 
      ],
    }; 
    const options = {
      plugins: {
        legend: {
            display: false
        }
      },
      indexAxis:'y',
      tooltips: {
        enabled: true,
      },
      layout: {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
      },
      title:{
        fontStyle: 'bold',
      },
     scales: {
      x: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
        labe :{
          fontSize:6,
        },
      }
      },
    }  
    function  llenadoTabla(datos,nombre)
    {
      return(
      <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem'
        }}>    
          <Bar
            id="graficaMuni"
            data={datos}
            width={400} height={75} 
            options={options}
          />  
 
      </Card>
      );
    }
    function  llenadoTabla2(datos,nombre)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
    ,paddingBottom:.5+'rem',height:7.5+'rem',width:27.5+'rem'}} >              
      <Bar
        id="graficaMuni"
        data={datos}
        width={300} height={80} 
        options={options}
      />   
    </Card>
    );
  }
  return (
    <Box display="flex" flexDirection="row" justifyContent={'center'}  >      
      {valor.Valido > 0 || valor.Pendiente > 0 || valor.Rechazado > 0 ?
     <span><Box display="flex"className={classes.contenedorCard}  >
        {llenadoTabla2(data3,process.env.REACT_APP_Promov)}
      </Box></span>/*  <Box display="flex"className={classes.contenedorCard} >
          {llenadoTabla(data2,process.env.REACT_APP_Promov)}
      </Box> */
    :<Box >
        {/* <Box display="flex"className={classes.contenedorCard} >
          {llenadoTabla(dataSinPerfil,process.env.REACT_APP_Promov)}
        </Box> */}
        <Box display="flex"className={classes.contenedorCard}  >
        {llenadoTabla2(data3,process.env.REACT_APP_Promov)}
      </Box>
      </Box>  
    }
    </Box>
  );
}